import Text from "../Text/Text";
import styles from "./NumberOfStep.module.scss";
import { useMediaQuery } from "react-responsive";
import { useOnboardingStepCountContext } from "./../../context/onboardingStepCount";

const NumberOfStep = ({ step }: { step?: any }) => {
  const smallScreen = useMediaQuery({
    query: `(max-width: 575px)`,
  });

  const { stepCount } = useOnboardingStepCountContext();
  return smallScreen ? (
    <div className={styles.stepNumber}>
      {/* <Text variant="small" className={styles.label}>{`Step ${step} of ${stepCount}`}</Text> */}
    </div>
  ) : null;
};

export default NumberOfStep;
