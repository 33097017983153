import React, { useState } from "react";
import Select, {
  components,
  ControlProps,
  SingleValueProps,
  ValueContainerProps,
} from "react-select";
import cn from "classnames";
import OnboardingFormError from "../OnboardingFormError/OnboardingFormError";

import styles from "./OnboardingFormSelect.module.scss";

interface IOnboardingFormSelect {
  field: any;
  form: any;
  setFormValues: (value: any) => void;
  options: Array<{ label: string; value: string }>;
  checkStateAvailability: (value: string) => void;
}

const Control = ({ children, ...props }: ControlProps<any, any>) => {
  return (
    <components.Control {...props} className={styles.Control}>
      <label className={styles.Placeholder} htmlFor="formSelect">
        State
      </label>
      {children}
    </components.Control>
  );
};

const ValueContainer = ({ children, ...props }: ValueContainerProps<any, any>) => {
  return (
    <components.ValueContainer {...props} className={styles.ValueContainer}>
      {children}
    </components.ValueContainer>
  );
};

const SingleValue = ({ children, ...props }: SingleValueProps<any, any>) => {
  return (
    <components.SingleValue {...props} className={styles.SingleValue}>
      {children}
    </components.SingleValue>
  );
};

const OnboardingFormSelect = (props: IOnboardingFormSelect) => {
  const { field, form, options, setFormValues, checkStateAvailability } = props;

  const [focus, setFocus] = useState(!!field?.value?.value);

  return (
    <div className={styles.wrapper}>
      <Select
        data-testid="stateForCare"
        controlShouldRenderValue
        name={field.name}
        inputId="formSelect"
        components={{
          Control,
          ValueContainer,
          SingleValue: SingleValue,
          Placeholder: () => null,
          DropdownIndicator: () => null,
          IndicatorSeparator: () => null,
        }}
        defaultInputValue={form?.values?.stateForCare}
        isClearable
        isSearchable
        options={options}
        onChange={(e) => {
          checkStateAvailability(e?.value);
          form.getFieldHelpers(field.name).setTouched();
          form.setFieldValue(field.name, e?.value);
          setFormValues((prevState: any) => ({ ...prevState, stateForCare: e?.value }));
        }}
        className={cn(
          styles.element,
          focus && styles.isFocus,
          field.value !== "" && !form.errors[field.name] && styles.isValid,
          form.touched[field.name] && form.errors[field.name] && styles.isAlert
        )}
        onMenuOpen={() => setFocus(true)}
      />

      {form.touched[field.name] && form.errors[field.name] && (
        <OnboardingFormError errors={form.errors} inputName={field.name} />
      )}
    </div>
  );
};

export default OnboardingFormSelect;
