import styles from "./DailyCard.module.scss";
import { confettiConfig } from "../../utils/confettiConfig";
import { IPatient } from "../../types/patient";
import Button from "../Button/Button";

import { CarePlanItemCompletionStatus, CarePlanItemSubType } from "../../utils/carePlantUtil";
import { useUpdatePlanItemStatus } from "../../hooks/useUpdatePlanItemStatus";
import { runGAForSpecificPartner } from "../../helpers/runGAForSpecificPartner";
import { partnersName } from "../../utils/partnerList";

import { ReactComponent as LinkIcon } from "../../assets/icons/DailyCard/Link.svg";
import { ReactComponent as BookVisitIcon } from "../../assets/icons/DailyCard/Book_Visit.svg";
import { ReactComponent as CoachIcon } from "../../assets/icons/DailyCard/Coach.svg";
import { ReactComponent as ContentIcon } from "../../assets/icons/DailyCard/Content.svg";
import { ReactComponent as FoodIcon } from "../../assets/icons/DailyCard/Food.svg";
import { ReactComponent as TrackIcon } from "../../assets/icons/DailyCard/Track.svg";
import { ReactComponent as MedicinesIcon } from "../../assets/icons/DailyCard/medicines.svg";
import { ReactComponent as ExerciseIcon } from "../../assets/icons/DailyCard/exercise.svg";
import { ReactComponent as ClipboardIcon } from "../../assets/icons/DailyCard/clipboard.svg";

import { ReactComponent as LinkCompleteIcon } from "../../assets/icons/DailyCard/Link-brown.svg";
import { ReactComponent as BookVisitCompleteIcon } from "../../assets/icons/DailyCard/Book_Visit-brown.svg";
import { ReactComponent as CoachCompleteIcon } from "../../assets/icons/DailyCard/Coach-brown.svg";
import { ReactComponent as ContentCompleteIcon } from "../../assets/icons/DailyCard/Content-brown.svg";
import { ReactComponent as FoodCompleteIcon } from "../../assets/icons/DailyCard/Food-brown.svg";
import { ReactComponent as TrackCompleteIcon } from "../../assets/icons/DailyCard/Track-brown.svg";
import { ReactComponent as MedicinesCompleteIcon } from "../../assets/icons/DailyCard/medicines-brown.svg";
import { ReactComponent as ExerciseCompleteIcon } from "../../assets/icons/DailyCard/exercise-brown.svg";
import { ReactComponent as ClipboardCompleteIcon } from "../../assets/icons/DailyCard/clipboard-brown.svg";
import { ReactComponent as MarkDoneIcon } from "../../assets/icons/DailyCard/markDone.svg";

import { useEffect, useState } from "react";
import Confetti from "react-dom-confetti";
import firebase from "firebase/compat/app";
import amplitude from "amplitude-js";
import Skeleton from "react-loading-skeleton";
// @ts-ignore
import { gtag, install } from "ga-gtag";
import ReactPixel from "react-facebook-pixel";
import TiktokPixel from "tiktok-pixel";

interface IProp {
  instruction: string;
  itemSubType: number;
  status: number;
  contentID: string;
  uid: string;
  uniqueID: string;
  patientData: IPatient;
  clickModalHandler: (
    type: number,
    contentID: string,
    url: string,
    description: string,
    status: number,
    uniqueID: string
  ) => void;
}

export const DailyCard = ({
  instruction,
  itemSubType,
  status,
  contentID,
  uid,
  uniqueID,
  patientData,
  clickModalHandler,
}: IProp) => {
  const [careTeamMember, setCareTeamMember] = useState<any>(null);
  const [checkInFormURL, setCheckInFormURL] = useState("");

  let firestore = firebase.firestore;
  const { COMPLETE, INCOMPLETE } = CarePlanItemCompletionStatus;

  const { updatePlanItemStatus, explosion } = useUpdatePlanItemStatus(patientData);

  const generateIcon = () => {
    switch (itemSubType) {
      case CarePlanItemSubType.CONTENT_MEDICATION:
        return status === COMPLETE ? <MedicinesCompleteIcon /> : <MedicinesIcon />;

      case CarePlanItemSubType.CARE_TEAM:
        if (careTeamMember?.careTeamMemberImageURL) {
          return <img src={careTeamMember?.careTeamMemberImageURL} alt="" />;
        }
        return <Skeleton circle={true} height={58} width={58} />;
      case CarePlanItemSubType.BOOK_APPOINTMENT:
        return status === COMPLETE ? <BookVisitCompleteIcon /> : <BookVisitIcon />;

      case CarePlanItemSubType.COACHING_CHECKIN:
        return status === COMPLETE ? <ClipboardCompleteIcon /> : <ClipboardIcon />;

      case CarePlanItemSubType.CONTENT_EXERCISE:
        return status === COMPLETE ? <ExerciseCompleteIcon /> : <ExerciseIcon />;

      case CarePlanItemSubType.BOOK_COACHING:
        return status === COMPLETE ? <CoachCompleteIcon /> : <CoachIcon />;

      case CarePlanItemSubType.CONTENT_GENERIC:
        return status === COMPLETE ? <ContentCompleteIcon /> : <ContentIcon />;

      case CarePlanItemSubType.TRACK_HEADACHES:
        return status === COMPLETE ? <TrackCompleteIcon /> : <TrackIcon />;

      case CarePlanItemSubType.FOOD:
        return status === COMPLETE ? <FoodCompleteIcon /> : <FoodIcon />;

      case CarePlanItemSubType.GENERIC_URL:
        return status === COMPLETE ? <LinkCompleteIcon /> : <LinkIcon />;

      default:
        return status === COMPLETE ? <ContentCompleteIcon /> : <ContentIcon />;
    }
  };

  const getDescription = () => {
    switch (itemSubType) {
      case CarePlanItemSubType.CONTENT_MEDICATION:
        return "Learn More";
      case CarePlanItemSubType.CARE_TEAM:
        return "Learn More";
      case CarePlanItemSubType.BOOK_APPOINTMENT:
        return "Book Visit";
      case CarePlanItemSubType.COACHING_CHECKIN:
        return "Check In";
      case CarePlanItemSubType.CONTENT_EXERCISE:
        return "Review";
      case CarePlanItemSubType.BOOK_COACHING:
        return "Book Coaching";
      case CarePlanItemSubType.CONTENT_GENERIC:
        return "Review";
      case CarePlanItemSubType.TRACK_HEADACHES:
        return "Learn More";
      case CarePlanItemSubType.FOOD:
        return "Review";
      case CarePlanItemSubType.GENERIC_URL:
        return "Review";
      default:
        return "Learn More";
    }
  };

  const onPress = () => {
    amplitude.getInstance().logEvent("care_plan_item_click", {
      "Item subtype": itemSubType,
      "Post ID": contentID ?? "None",
    });

    if (itemSubType === CarePlanItemSubType.BOOK_APPOINTMENT) {
      if (patientData?.partnerName === partnersName.THERANICA) {
        const options = {
          debug: false,
        };
        // @ts-ignore
        ReactPixel.init("669817293553583", undefined, options);
        ReactPixel.pageView();
        ReactPixel.trackCustom("neura_book-visit-button-clicked");

        TiktokPixel.init("C2J59L4QTD93QKDL0NF0", undefined, options);
        TiktokPixel.pageView();
        // @ts-ignore
        TiktokPixel.track("neura_book-visit-button-clicked");

        install("AW-675319768", { send_page_view: false });
        gtag("event", "conversion", {
          send_to: "AW-675319768/X37QCL_Y28gZENifgsIC",
          value: 1.0,
          currency: "USD",
        });
      }

      const obj = {
        patientPartner: patientData?.partnerName,
        partnerName: partnersName.THERANICA,
        event: "neura_book-visit-button-clicked",
      };
      runGAForSpecificPartner(obj);
    }

    clickModalHandler(itemSubType, contentID, checkInFormURL, instruction, status, uniqueID);
  };

  useEffect(() => {
    if (itemSubType === CarePlanItemSubType.CARE_TEAM) {
      const ctmID = contentID;
      firestore()
        .collection("care_team")
        .doc(ctmID)
        .get()
        .then(async (docSnapshot) => {
          const ctm = docSnapshot.data();
          const imageURL = await firebase
            .storage()
            .refFromURL("gs://gezunt-c3bfe.appspot.com/doctors/" + ctm?.imageFileName)
            .getDownloadURL();
          setCareTeamMember({
            ...ctm,
            ...{ careTeamMemberImageURL: imageURL },
          });
        });
    } else if (itemSubType === CarePlanItemSubType.COACHING_CHECKIN) {
      firestore()
        .collection("patient_forms")
        .doc("coachingCheckinForm")
        .get()
        .then((docSnapshot) => {
          setCheckInFormURL(docSnapshot.get("url") + "?patientId=" + uid);
        });
    }
  }, [firestore, contentID, itemSubType, uid]);

  return (
    <div className={`${styles.wrapper} ${status === 1 ? styles.wrapperDone : null}`}>
      <div className={styles.wrapper__content}>
        <div className={styles.wrapper__content__iconText}>
          <div className={styles.wrapper__content__image}>{generateIcon()}</div>
          <h3 className={styles.title}>
            {itemSubType === CarePlanItemSubType.CARE_TEAM ? (
              careTeamMember?.name ? (
                careTeamMember?.name + ", " + careTeamMember?.title
              ) : (
                <Skeleton height={30} />
              )
            ) : (
              instruction
            )}
          </h3>
        </div>
      </div>
      <div
        className={styles.wrapper__mark}
        onClick={async (e: React.MouseEvent<HTMLInputElement>) =>
          updatePlanItemStatus(e, uniqueID, status)
        }
      >
        <Confetti active={explosion} config={confettiConfig} />
        {status === INCOMPLETE && <p className={styles.markAsDone}>Mark as Done</p>}
        {status === COMPLETE && (
          <p className={styles.done}>
            <MarkDoneIcon /> Done
          </p>
        )}
      </div>
      <Button onClick={onPress} tag="button" title={getDescription()} className={styles.button} />
    </div>
  );
};
