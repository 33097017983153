import { useEffect, useState } from "react";

import { IPatient, IPlanForToday } from "../../types/patient";
import { ITab, Tabs } from "../Tabs/Tabs";

import styles from "./UserPlan.module.scss";
import homeStyles from "../../views/HomePage/HomePage.module.scss";

import { DailyCard } from "../DailyCard/DailyCard";
import { ResourcesList } from "../ResourcesList/ResourcesList";
import { conditions } from "../../utils/conditionFocus";
import Button from "../Button/Button";

import { ReactComponent as Play } from "../../assets/images/svg/play.svg";
import { ReactComponent as Apple } from "../../assets/images/svg/apple.svg";
import { ReactComponent as SoloLogo } from "../../assets/images/svg/logo-solo.svg";
import { ReactComponent as PlayMobile } from "../../assets/images/svg/playmarket-mobile.svg";
import { ReactComponent as AppleMobile } from "../../assets/images/svg/appstore-mobile.svg";
import { ReactComponent as ArrowIcon } from "../../assets/icons/back.svg";

import { firestore } from "../../config";
import { doc, getDoc } from "firebase/firestore";

import { useHistory } from "react-router-dom";
import amplitude from "amplitude-js";
import Pagination from "rc-pagination";
import { GetHelp } from "../GetHelp/GetHelp";
import { CarePlanItemCompletionStatus } from "../../utils/carePlantUtil";
import { useRefreshPlanItemCountContext } from "../../context/refreshPlanItemCount";

interface IProps {
  uid: string;
  patientData: IPatient;
  clickModalHandler: (
    type: number,
    contentID: string | number,
    url: string,
    description: string,
    status: number,
    uniqueID: string
  ) => void;
  referModalHandler: () => void;
  bookVisit: () => void;
  openQRCode: () => void;
  isResponsiveView: boolean;
}

const downloadLinkIcons = [
  { icon: <Apple />, platform: "ios" },
  { icon: <Play />, platform: "android" },
];

const downloadLinkIconsMobile = [
  { icon: <AppleMobile />, platform: "ios" },
  { icon: <PlayMobile />, platform: "android" },
];

const tabs: ITab[] = [
  { title: "Open", id: 1 },
  { title: "Completed ", id: 2 },
];

export const UserPlan = ({
  uid,
  patientData,
  clickModalHandler,
  referModalHandler,
  bookVisit,
  openQRCode,
  isResponsiveView,
}: IProps) => {
  const [completedPlanItems, setCompletedPlanItems] = useState(0);
  const [totalPlanItems, setTotalPlanItems] = useState(0);

  const [activeTab, setActiveTab] = useState(1);
  const [current, setCurrent] = useState<number>(1);
  const [size, setSize] = useState(5);
  const [showMore, setShowMore] = useState(false);
  const history = useHistory();

  const { refreshPlanItemCount } = useRefreshPlanItemCountContext();

  const newPerPage =
    patientData && patientData?.planForToday && Math.ceil(patientData.planForToday.length / size);

  const handleShowMoreOrLess = () => {
    setShowMore((prevState) => !prevState);
  };

  const getData = (current: number, pageSize: number): IPlanForToday[] => {
    if (patientData?.planForToday) {
      if (showMore) return patientData.planForToday;
      else return patientData?.planForToday?.slice((current - 1) * pageSize, current * pageSize);
    }
    return [];
  };

  const PerPageChange = (value: number) => {
    setSize(value);
    if (current > newPerPage!) {
      setCurrent(newPerPage!);
    }
  };
  const PrevNextArrow = (curr: any, type: string) => {
    if (type === "prev") {
      return (
        <div className={`${styles.arrow} ${styles.arrowPrev} ${curr === 0 && styles.disabled}`}>
          <ArrowIcon />
        </div>
      );
    }
    if (type === "next") {
      return (
        <div
          className={`${styles.arrow} ${styles.arrowNext} ${
            newPerPage === current && styles.disabled
          }`}
        >
          <ArrowIcon />
        </div>
      );
    }
  };

  const PaginationChange = (
    page: React.SetStateAction<number>,
    pageSize: React.SetStateAction<number>
  ) => {
    setCurrent(page);
    setSize(pageSize);
  };

  const downloadEvent = (event: string, item: any) => {
    // @ts-ignore
    window?.dataLayer.push({
      event: event,
    });
    amplitude.getInstance().logEvent(event);

    window.open(
      item.platform === "ios"
        ? "https://apps.apple.com/us/app/id1562391775"
        : "https://play.google.com/store/apps/details?id=com.gezuntpatient",
      "_blank"
    );
  };

  const fetchPatientData = async (uid: string) => {
    if (!uid) return;
    let subscription: any;
    try {
      subscription = await getDoc(doc(firestore, "patients", uid));
      let completed = 0;
      let total = 0;

      const planItems = subscription.get("planForToday") ?? [];

      if (subscription && subscription.exists()) {
        for (const planItem of planItems) {
          if (planItem.status === CarePlanItemCompletionStatus.COMPLETE) {
            completed++;
            total++;
          } else if (planItem.status === CarePlanItemCompletionStatus.INCOMPLETE) {
            total++;
          }
        }

        setCompletedPlanItems(completed);
        setTotalPlanItems(total);
      }
      return () => subscription;
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    setShowMore(false);
  }, [isResponsiveView]);

  useEffect(() => {
    fetchPatientData(uid);
  }, [refreshPlanItemCount, uid, completedPlanItems, firestore]);

  return (
    <div className={homeStyles.contentLeft}>
      <div className={homeStyles.plan}>
        <div className={homeStyles.planLeft}>
          <div className={homeStyles.planText1}>Hi {patientData?.firstName}</div>
          <div className={homeStyles.planText2}>Welcome to Neura Health.</div>
        </div>
      </div>
      <div className={styles.wrapper}>
        {!patientData?.planForToday || patientData?.planForToday?.length === 0 ? (
          <GetHelp patientData={patientData} bookVisit={bookVisit} openQRCode={openQRCode} />
        ) : (
          // <Tabs tabs={tabs} activeTab={activeTab} setActiveTab={setActiveTab}>
          <div className={styles.wrapper__backgroundContent}>
            <div className={styles.wrapper__leftTasks}>
              <div>
                <h1 className={styles.wrapper__leftTasks__heading}>Your Care Plan</h1>
                {completedPlanItems > 0 && (
                  <p className={styles.wrapper__leftTasks__subtitle}>
                    You completed <strong>{completedPlanItems}</strong> task
                    {completedPlanItems > 1 && "s"}!
                  </p>
                )}
              </div>
              <div className={styles.wrapper__leftTasks__pagination}>
                {!isResponsiveView && patientData?.planForToday?.length! > 5 && (
                  <Pagination
                    className="pagination-data"
                    showTotal={(total, range) => `${range[0]}-${range[1]} of ${total}`}
                    onChange={PaginationChange}
                    total={patientData?.planForToday?.length}
                    current={current}
                    pageSize={size}
                    showSizeChanger={false}
                    itemRender={PrevNextArrow}
                    onShowSizeChange={PerPageChange}
                    style={{ display: "flex", alignItems: "center" }}
                  />
                )}
              </div>
            </div>
            {/* {activeTab === 1 && ( */}
            <>
              {patientData && patientData?.planForToday?.length ? (
                getData(current, size).map((planItem: IPlanForToday) => (
                  <DailyCard
                    key={planItem.uniqueID}
                    uniqueID={planItem.uniqueID}
                    uid={uid}
                    instruction={planItem.instruction}
                    itemSubType={planItem.subType}
                    status={planItem.status}
                    clickModalHandler={clickModalHandler}
                    contentID={planItem.content}
                    patientData={patientData}
                  />
                ))
              ) : (
                <div>
                  <div className={styles.emptyTitle}>
                    <p>empty </p>
                    {history.location.pathname === "/thankyou"
                      ? "We are setting up your personalized care plan..."
                      : "No action items for today"}
                  </div>
                </div>
              )}

              {patientData?.planForToday.length > size && (
                <Button
                  tag="button"
                  title={showMore ? "Show less" : "Show more"}
                  className={styles.showMoreButton}
                  onClick={handleShowMoreOrLess}
                />
              )}
            </>
            {/* {activeTab === 2 && (
                // All content...
              )} */}
          </div>
          // </Tabs>
        )}
      </div>

      <div className={styles.wrapperResourcesList}>
        <ResourcesList isResponsiveView={isResponsiveView} />
      </div>

      {patientData?.conditionFocus?.includes(conditions.HEADACHE_MIGRAINE) && (
        <div className={homeStyles.bottom}>
          <div className={homeStyles.bottomBlock}>
            <SoloLogo />
            <div className={homeStyles.bottomText}>Download our app to track your headaches</div>
          </div>
          <div className={isResponsiveView ? homeStyles.bottomIconsMobile : homeStyles.bottomIcons}>
            {(isResponsiveView ? downloadLinkIconsMobile : downloadLinkIcons).map(
              (item: any, index: number) => (
                <button
                  key={`${new Date()}${index}`}
                  onClick={() =>
                    downloadEvent(`onboarding_v2_download_${item.platform}_click`, item)
                  }
                >
                  {item.icon}
                </button>
              )
            )}
          </div>
        </div>
      )}
      {patientData?.affiliateLink && !patientData.partnerName ? (
        <div className={homeStyles.bottomTextReferral}>
          Refer a friend:{" "}
          <button className={homeStyles.bottomTextReferralLink} onClick={referModalHandler}>
            Give them $15 off, get $30 for yourself
          </button>
          .
        </div>
      ) : null}
    </div>
  );
};
