import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
// @ts-ignore
import { gtag, install } from "ga-gtag";
import ReactPixel from "react-facebook-pixel";
import TiktokPixel from "tiktok-pixel";
import styles from "./HomePage.module.scss";
import firebase from "firebase/compat/app";
import Navigation from "../../components/Navigation/Navigation";
import ModalLayout from "../../components/ModalLayout/ModalLayout";
import { UserPlan } from "../../components/UserPlan/UserPlan";
import BlogReader from "../../components/BlogReader/BlogReader";
import { CarePlanItemSubType } from "../../utils/carePlantUtil";
import TrackHeadache from "../../components/TrackHeadache/TrackHeadache";
import { BookVisitModal } from "../../components/BookVisitModal/BookVisitModal";
import VisitNotes from "../../components/VisitNotes/VisitNotes";
import Loader from "../../components/Loader/Loader";
import Visits from "../../components/Visits/Visits";
import QRComponent from "../../components/QRComponent/QRComponent";
import bottomNavMenu from "../../helpers/bottomNavMenu";
import amplitude from "amplitude-js";
import Neurology from "../../assets/images/Neurology-Clinic.png";
import DownloadComponent from "../../components/DownloadComponent/DownloadComponent";
import ReferComponent from "../../components/ReferComponent/ReferComponent";
import Drawer from "react-modern-drawer";
import "react-modern-drawer/dist/index.css";
import { useMediaQuery } from "react-responsive";
import breakpoints from "../../assets/styles/variables/breakpoints.module.scss";
import { ReactComponent as Cancel } from "../../assets/images/svg/cancel.svg";
import FirebaseApi from "../../api/FirebaseApi/FirebaseApi";
import { UpgradePaymentsInfoModal } from "../../components/UpgradePaymentsInfoModal/UpgradePaymentsInfoModal";
import { partnersName } from "../../utils/partnerList";

export interface BlogReaderProps {
  type: number;
  contentID?: string | number;
  url?: string;
  description?: string;
  status: number;
  uniqueID: string;
}

function HomePage(props: any) {
  const history = useHistory();

  const { uid } = props;
  const [isOpenCard, setIsOpenCard] = useState<boolean>(false);

  const [subItem, setSubItem] = useState<BlogReaderProps | null>(null);
  const [isVisitNotes, setIsVisitNotes] = useState(false);
  const [isVisitModal, setIsVisitModal] = useState(false);
  const [isReferralModal, setIsReferralModal] = useState(false);
  const [isOpenUpgradeModal, setIsOpenUpgradeModal] = useState(false);
  const [isExpandQR, setisExpandQR] = useState(false);
  const [isFirstTime, setIsFirstTime] = useState(false);
  const [paywallEnabled, setPaywallEnabled] = useState(false);

  const [pastVisit, setPastVisit] = useState<any>(null);
  const [subscriptionStatus, setSubscriptionStatus] = useState<any>(null);
  const [patientData, setPatientData] = useState<any>(null);
  const [pastDueIgnoreTime, setPastDueIgnoreTime] = useState<any>(Date.now() - 1000 * 3600 * 24);

  const [loading, setLoading] = useState<boolean>(false);

  const isResponsiveView = useMediaQuery({
    query: `(max-width: ${breakpoints["sm"]}px)`,
  });

  let firestore = firebase.firestore;

  const clickModalHandler = (
    type: number,
    contentID: string | number,
    url: string,
    description: string,
    status: number,
    uniqueID: string
  ) => {
    setSubItem({
      type,
      ...(contentID && { contentID }),
      ...(url && { url }),
      ...(description && { description }),
      status,
      uniqueID,
    });
    setIsOpenCard(true);
  };

  const referFriend = () => {
    setIsOpenCard(true);
    setIsReferralModal(true);
  };

  const portalsPixels = () => {
    const options = {
      debug: false,
    };
    // @ts-ignore
    ReactPixel.init("669817293553583", undefined, options);
    ReactPixel.pageView();
    ReactPixel.trackCustom("neura_book-visit-button-clicked");

    TiktokPixel.init("C2J59L4QTD93QKDL0NF0", undefined, options);
    TiktokPixel.pageView();
    // @ts-ignore
    TiktokPixel.track("neura_book-visit-button-clicked");

    install("AW-675319768", { send_page_view: false });
    gtag("event", "conversion", {
      send_to: "AW-675319768/X37QCL_Y28gZENifgsIC",
      value: 1.0,
      currency: "USD",
    });
  };

  const bookVisit = (isDisabled?: boolean) => {
    if (isDisabled) {
      handleOpenUpgradeModal();
      return;
    }
    if (patientData?.partnerName === partnersName.THERANICA) {
      // This event is for one specific partner, Theranica
      // @ts-ignore
      window?.dataLayer?.push({ event: "neura_book-visit-button-clicked" });
      portalsPixels();
    }

    setIsOpenCard(true);
    setIsVisitNotes(true);
  };

  const handleOpenUpgradeModal = () => {
    setIsOpenCard(false);
    setIsVisitNotes(false);
    setIsOpenUpgradeModal(true);
  };

  const openVisitNotes = (visitNote: any, careTeam: any) => {
    setIsOpenCard(true);

    const provider = careTeam[visitNote.provider];

    setPastVisit({
      provider: provider.name,
      date: visitNote.date,
      image: provider.careTeamMemberImageURL,
      stack: provider.title,
      noteContent: visitNote.content,
      acuityAppointmentLink: provider.acuityAppointmentLink,
    });
  };

  const openQRCode = () => {
    setIsOpenCard(true);
    setisExpandQR(true);
  };

  const openVisits = () => {
    setIsOpenCard(true);
    setIsVisitModal(true);
  };

  const openStripePortal = async () => {
    const functions = new FirebaseApi().firebaseFunctions();

    window.location.href = await functions.createCustomerPortalSession({
      // @ts-ignore
      patientID: patientData.uid,
      isTest: !(process.env.REACT_APP_ENV === "production"),
    });
  };

  function handleClose(): void {
    setIsOpenCard(false);
    setIsVisitNotes(false);
    setIsVisitModal(false);
    setisExpandQR(false);
    setIsFirstTime(false);
    setIsReferralModal(false);
    setPastVisit(null);
    setSubItem(null);
    setIsOpenUpgradeModal(false);
  }

  function logOut() {
    amplitude.getInstance().setUserId(null);

    // @ts-ignore
    Kustomer.logout((_callbackResponse, _error) => {
      // @ts-ignore
      Kustomer.stop();
    });

    firebase.auth().signOut();
    history.push({
      pathname: "/login",
      search: window.location.search,
    });
    window.location.reload();
  }

  const bookVisitComponent = <BookVisitModal uid={uid} patientData={patientData} />;

  const getModalComponent = () => {
    if (isVisitNotes) {
      return bookVisitComponent;
    }
    if (pastVisit) {
      return (
        <VisitNotes
          handleOpenUpgradeModal={handleOpenUpgradeModal}
          visitNote={pastVisit}
          patientData={{
            firstName: patientData.firstName,
            lastName: patientData.lastName,
            email: patientData.email,
            phoneNumber: patientData.phoneNumber,
            isOnMaintenanceMode: patientData.isOnMaintenanceMode,
          }}
        />
      );
    }

    if (isExpandQR) {
      return <QRComponent />;
    }

    if (isFirstTime) {
      return <DownloadComponent isResponsiveView={isResponsiveView} />;
    }

    if (isReferralModal) {
      return <ReferComponent referralLink={patientData.affiliateLink} />;
    }

    if (isVisitModal) {
      return (
        <Visits
          uid={uid}
          isOnMaintenanceMode={patientData?.isOnMaintenanceMode}
          bookVisit={bookVisit}
          openVisitNotes={openVisitNotes}
        />
      );
    }

    if (subItem) {
      const BlogComponent = <BlogReader subItem={subItem} patientData={patientData} />;

      switch (subItem.type) {
        case CarePlanItemSubType.CONTENT_MEDICATION:
          return BlogComponent;
        case CarePlanItemSubType.CARE_TEAM:
          history.push({
            pathname: "/doctor/" + subItem.contentID,
            search: window.location.search,
          });
          return;
        case CarePlanItemSubType.BOOK_APPOINTMENT:
          return bookVisitComponent;
        case CarePlanItemSubType.COACHING_CHECKIN:
          setIsOpenCard(false);
          window.open(subItem.url, "_blank");
          return;
        case CarePlanItemSubType.CONTENT_EXERCISE:
          return BlogComponent;
        case CarePlanItemSubType.BOOK_COACHING:
          return bookVisitComponent;
        case CarePlanItemSubType.CONTENT_GENERIC:
          return BlogComponent;
        case CarePlanItemSubType.TRACK_HEADACHES:
          return <TrackHeadache />;
        case CarePlanItemSubType.FOOD:
          return BlogComponent;
        case CarePlanItemSubType.GENERIC_URL:
          setIsOpenCard(false);
          window.open(`${subItem.contentID}`, "_blank");
          return;
        default:
          return;
      }
    }
  };

  const renderBottomNavMenu = bottomNavMenu.map((el) => (
    <li key={el.title}>
      <a href={`https://www.neurahealth.co${el.to}`} rel="noreferrer" target="_blank">
        {el.title}
      </a>
    </li>
  ));

  useEffect(() => {
    document.body.classList.toggle("no-chat", true);
  }, [isResponsiveView]);

  useEffect(() => {
    if (patientData && patientData.kustomerID) {
      // @ts-ignore
      Kustomer.start({ brandId: "61b91090a1a78441a08b05a8" }, function () {
        const functions = new FirebaseApi().firebaseFunctions();
        functions.getJWTToken(uid).then(async (jwtToken) => {
          // @ts-ignore
          await Kustomer.login({ jwtToken: jwtToken });
        });
      });
    }
  }, [patientData, uid]);

  useEffect((): any => {
    setLoading(true);
    let subscription: Promise<void> = firestore()
      .collection("launch_controls")
      .doc("paywall")
      .get()
      .then((docSnapshot) => {
        setLoading(false);

        if (!docSnapshot) {
          return;
        }
        const paywallData: any = docSnapshot.data();
        setPaywallEnabled(paywallData.enabled);
      });
    return () => subscription;
  }, [firestore]);

  useEffect((): any => {
    let subscription: any;

    if (uid) {
      setLoading(true);

      subscription = firestore()
        .collection("patients")
        .doc(uid)
        .onSnapshot(async (docSnapshot: any) => {
          if (!docSnapshot) {
            setLoading(false);
            return;
          }

          const data = docSnapshot.data();

          await setSubscriptionStatus(docSnapshot.get("subscriptionStatus"));

          setPatientData(data);

          if (data?.activationTime) {
            const difference = Date.now() / 1000 - data?.activationTime;
            const isFirstLogin = localStorage.getItem("isFirstLogin");

            if (difference <= 24 * 3600 && !isFirstLogin) {
              setIsFirstTime(true);
              setIsOpenCard(true);
              localStorage.setItem("isFirstLogin", "logged in");
            }
          }
          setLoading(false);
        });
    }

    return () => subscription;
  }, [firestore, uid]);

  useEffect(() => {
    const body: any = document.querySelector("body");

    body.style.overflow = isOpenCard ? "hidden" : "auto";

    return () => {
      body.style.overflow = "auto";
    };
  }, [isOpenCard]);

  if (loading) return <Loader />;

  if (paywallEnabled) {
    const validStatuses = ["active", "trialing", "past_due"];

    if (subscriptionStatus && !validStatuses.includes(subscriptionStatus)) {
      return (
        <div className={styles.modal}>
          <div className={styles.modalWrap}>
            <img
              style={{ height: 230, width: 360, marginBottom: 10 }}
              src={Neurology}
              alt={"Neurology"}
            />
            <div className={styles.modalText}>
              There was a problem with your subscription. Please check your payment method and
              update it if needed.
            </div>

            <div className={styles.modalText}>
              Contact support@neurahealth.co if you have questions.
            </div>

            <button className={styles.modalButton} onClick={openStripePortal}>
              Payment Settings
            </button>

            <button className={styles.modalButtonSecondary} onClick={logOut}>
              Log Out
            </button>
          </div>
        </div>
      );
    }

    // If the status is past due, show a warning but let them dismiss
    // it for 24 hours / until next log out.
    if (subscriptionStatus === "past_due") {
      if (Date.now() - pastDueIgnoreTime > 1000 * 3600 * 24) {
        return (
          <div className={styles.modal}>
            <div className={styles.modalWrap}>
              <img style={{ height: 230, width: 360 }} src={Neurology} alt={"Neurology"} />
              <div className={styles.modalText}>
                We were not able to process payment on your card. Please visit our website to ensure
                that your payment details are valid.
              </div>

              <div className={styles.modalText}>
                Contact support@neurahealth.co if you have questions.
              </div>

              <button
                className={styles.modalButton}
                onClick={() => setPastDueIgnoreTime(Date.now())}
              >
                Dismiss
              </button>
            </div>
          </div>
        );
      }
    }
  }

  return (
    <>
      <div className={styles.home}>
        <div className={styles.container}>
          <Navigation
            patientData={patientData}
            isResponsiveView={isResponsiveView}
            openVisitsMobile={openVisits}
          />
          <div className={styles.content}>
            <UserPlan
              uid={uid}
              patientData={patientData}
              clickModalHandler={clickModalHandler}
              isResponsiveView={isResponsiveView}
              referModalHandler={referFriend}
              bookVisit={bookVisit}
              openQRCode={openQRCode}
            />
            <Visits
              uid={uid}
              bookVisit={bookVisit}
              openVisitNotes={openVisitNotes}
              inVisible={true}
              isOnMaintenanceMode={patientData?.isOnMaintenanceMode}
            />
          </div>
        </div>
        <div className={styles.footer}>
          <ul>{renderBottomNavMenu}</ul>
        </div>
        {!isResponsiveView && isOpenUpgradeModal && (
          <ModalLayout
            handleClose={handleClose}
            style={{ maxHeight: "450px", padding: "78px 42px" }}
          >
            <UpgradePaymentsInfoModal handleClose={handleClose} />
          </ModalLayout>
        )}

        {isResponsiveView && (
          <Drawer
            open={isOpenUpgradeModal}
            onClose={handleClose}
            direction="bottom"
            className={styles.drawerInfo}
          >
            <button className={styles.close} onClick={handleClose}>
              <Cancel />
            </button>
            <UpgradePaymentsInfoModal handleClose={handleClose} />
          </Drawer>
        )}

        {!isResponsiveView && isOpenCard && (
          <ModalLayout style={pastVisit ? { overflow: "hidden" } : null} handleClose={handleClose}>
            {getModalComponent()}
          </ModalLayout>
        )}

        {isResponsiveView && isOpenCard && (
          <Drawer
            open={isOpenCard}
            onClose={handleClose}
            direction="bottom"
            className={styles.drawer}
          >
            <button className={styles.close} onClick={handleClose}>
              <Cancel />
            </button>
            {getModalComponent()}
          </Drawer>
        )}
      </div>
    </>
  );
}

export default HomePage;
