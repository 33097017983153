import { Link } from "react-router-dom";
import { Field, Form, Formik, FormikHelpers } from "formik";
import cn from "classnames";
import styles from "./SetNewPasswordForm.module.scss";
import { PasswordSchema } from "../../schemas/authForm";

import OnboardingFormDescription from "../../components/OnboardingForm/OnboardingFormComponents/OnboardingFormDescription/OnboardingFormDescription";
import OnboardingFormHeading from "../../components/OnboardingForm/OnboardingFormComponents/OnboardingFormHeading/OnboardingFormHeading";
import OnboardingFormTextInput from "../../components/OnboardingForm/OnboardingFormComponents/OnboardingFormTextInput/OnboardingFormTextInput";
import OnboardingFormSubmit from "../../components/OnboardingForm/OnboardingFormComponents/OnboardingFormSubmit/OnboardingFormSubmit";
import OnboardingFormPasswordValidation from "../../components/OnboardingForm/OnboardingFormComponents/OnboardingFormPasswordValidation/OnboardingFormPasswordValidation";
import { useMediaQuery } from "react-responsive";
import { useValidatePassword } from "../../hooks/useValidatePassword";
import { ReactComponent as LeafIcon } from "../../assets/images/svg/leaf_field.svg";

type FormikValue = { password: string };

export const SetNewPasswordForm = ({
  onSubmit,
  title,
  subTitle,
  labelButton = "Reset Password",
  visibleLinks,
}: {
  title: string;
  subTitle: string;
  visibleLinks?: boolean;
  labelButton?: string;
  onSubmit: ({ password }: { password: string }) => void;
}) => {
  const { passwordStatus, validatePassword } = useValidatePassword();

  const smallScreen = useMediaQuery({
    query: `(max-width: 575px)`,
  });

  const resetPassword = (values: FormikValue, action: FormikHelpers<FormikValue>) => {
    onSubmit(values);
    action.setSubmitting(false);
  };

  return (
    <Formik
      initialValues={{ password: "" }}
      onSubmit={resetPassword}
      validationSchema={PasswordSchema}
      className={styles.form}
    >
      {({ isValid, isSubmitting }) => {
        return (
          <Form
            className={
              (cn("d-flex flex-column h-100", {
                [styles.minHeight]: smallScreen,
              }),
              styles.form)
            }
            id="password_recovery"
          >
            <OnboardingFormHeading title={title} />
            <OnboardingFormDescription text={subTitle} />

            <div className={styles.wrapperField}>
              <LeafIcon className={styles.leafImage} />
              <Field
                label="Password"
                name="password"
                type="password"
                component={OnboardingFormTextInput}
                see
                validate={validatePassword}
              />
            </div>
            <OnboardingFormPasswordValidation {...passwordStatus} />

            <div className={styles.submitButton}>
              <OnboardingFormSubmit
                title={labelButton}
                disabled={!isValid}
                isSubmitting={isSubmitting}
              />
            </div>
            {visibleLinks && (
              <OnboardingFormDescription
                className={styles.logInText}
                text={
                  <span>
                    <Link
                      className={styles.link}
                      to={{ pathname: "/onboarding", search: window.location.search }}
                      onClick={(event) => isSubmitting && event.preventDefault()}
                    >
                      Sign up
                    </Link>{" "}
                    or{" "}
                    <Link
                      className={styles.link}
                      to={{ pathname: "/login", search: window.location.search }}
                      onClick={(event) => isSubmitting && event.preventDefault()}
                    >
                      Log in
                    </Link>
                  </span>
                }
              />
            )}
          </Form>
        );
      }}
    </Formik>
  );
};
