import { useEffect, useRef, useState } from "react";

import styles from "./BookVisitModal.module.scss";

import LoaderSpinner from "../LoaderSpinner/LoaderSpinner";
import firebase from "firebase/compat/app";

import BookCard from "../BookCard/BookCard";
import { Select } from "../Select/Select";
import { Tabs, ITab } from "../Tabs/Tabs";

import {
  getBookingURI,
  bookingProviderType,
  bookingPatientType,
} from "../../helpers/getBookingURI";
import { conditions } from "../../utils/conditionFocus";
import { IProvider } from "../../types/provider";
import { IPatient } from "../../types/patient";
import { conditionType } from "../../types/condition";
interface IBookProps {
  uid: string;
  patientData: IPatient;
}
interface ICareTeamGroup {
  title: string;
  list: string[];
}
interface IOption {
  value: string;
  label: string;
}

const tabs: ITab[] = [
  { title: "Providers", id: 1 },
  { title: "Coaches", id: 2 },
];

const conditionFocusString: Record<conditionType, string> = {
  [conditions.HEADACHE_MIGRAINE]: "Headache/Migraine",
  [conditions.SLEEP]: "Sleep",
  [conditions.EPILEPSY]: "Epilepsy",
  [conditions.CONCUSSION_TBI]: "Concussion/TBI",
  [conditions.STROKE]: "Stroke",
  [conditions.TREMOR]: "Tremor",
  [conditions.OTHER_UNDIAGNOSED]: "Other/Undiagnosed",
};

export const BookVisitModal = ({ uid: patientID, patientData }: IBookProps) => {
  const [careTeam, setCareTeam] = useState<IProvider[]>([]);
  const [activeTab, setActiveTab] = useState(1);
  const [loader, setLoader] = useState(false);
  const [options, setOptions] = useState<IOption[]>([]);
  const [specialtySelect, setSpecialitySelect] = useState<string>("");

  const selectRef = useRef(null);

  let firestore = firebase.firestore;

  const [careTeamGroups, setCareTeamGroups] = useState<ICareTeamGroup[]>([]);

  useEffect(() => {
    if (!patientData.conditionFocus) return;
    const opt = patientData.conditionFocus.map((condition) => ({
      label: conditionFocusString[condition],
      value: condition,
    }));
    setOptions(opt);
    setSpecialitySelect(patientData.conditionFocus[0]);
  }, [patientData.conditionFocus]);

  useEffect(() => {
    if (!specialtySelect) return;
    setCareTeamGroups([]);

    setLoader(true);

    firebase
      .functions()
      .httpsCallable(activeTab === 2 ? "getAvailableCoaches" : "getAvailableProvidersV3")({
        patientID,
        conditionFocus: specialtySelect,
      })
      .then((response) => {
        const responseData = response.data;
        const slotCounts = responseData.slotCounts;
        const unsortedCTMIDs = Object.keys(slotCounts);
        const ctmIDs = unsortedCTMIDs.sort((a, b) => {
          return slotCounts[a] < slotCounts[b] ? 1 : -1;
        });

        firestore()
          .collection("care_team")
          .where(firestore.FieldPath.documentId(), "in", ctmIDs)
          .get()
          .then((providerQS) => {
            let careTeamArray: any[] = [];
            let careTeamMap: any = {};

            // Arrange docs in the same order as the IDs
            for (const providerDoc of providerQS.docs) {
              careTeamMap[providerDoc.id] = providerDoc;
            }

            for (const ctmID of ctmIDs) {
              careTeamArray.push({
                ...careTeamMap[ctmID].data(),
                ...{ careTeamMemberID: ctmID, slotCount: slotCounts[ctmID] },
              });
            }

            const teamMemberImageURLPromises = careTeamArray.map((careTeamMember) =>
              firebase
                .storage()
                .refFromURL("gs://gezunt-c3bfe.appspot.com/doctors/" + careTeamMember.imageFileName)
                .getDownloadURL()
            );
            Promise.all(teamMemberImageURLPromises).then((teamMemberImageURLs) => {
              let finalTeamMemberArray = [];
              let teamMemberLen = teamMemberImageURLs.length;
              for (let i = 0; i < teamMemberLen; i++) {
                finalTeamMemberArray.push({
                  ...careTeamArray[i],
                  ...{ careTeamMemberImageURL: teamMemberImageURLs[i] },
                });
              }
              setCareTeam(finalTeamMemberArray);
              setCareTeamGroups(responseData.data);
              setCareTeam(finalTeamMemberArray);
            });
          })
          .then(() => setLoader(false));
      });
  }, [firestore, patientID, patientData, activeTab, specialtySelect]);

  const bookTheVisit = (provider: bookingProviderType) => {
    const patient: bookingPatientType = {
      firstName: patientData.firstName,
      lastName: patientData.lastName,
      email: patientData.email,
      phoneNumber: patientData.phoneNumber,
    };

    window.open(getBookingURI(provider, patient), "_blank");
  };

  const renderTabView = () => (
    <div className={styles.doctors}>
      {loader ? (
        <div className={styles.loader}>
          <LoaderSpinner />
        </div>
      ) : (
        <>
          {careTeamGroups.map((careTeamGroup: ICareTeamGroup) => (
            <div key={`ctmGroupSection${Math.random()}`}>
              <p className={styles.title}>{careTeamGroup.title}</p>
              {careTeam.map((careTeamMember) => {
                if (
                  careTeamMember.careTeamMemberID &&
                  careTeamGroup.list.includes(careTeamMember.careTeamMemberID)
                ) {
                  return (
                    <BookCard
                      careTeamMember={careTeamMember}
                      onClick={() => {
                        const { acuityAppointmentLink } = careTeamMember;
                        bookTheVisit({ acuityAppointmentLink });
                      }}
                    />
                  );
                } else {
                  return null;
                }
              })}
            </div>
          ))}
        </>
      )}
    </div>
  );

  return (
    <div className={styles.book}>
      <div className={styles.bookTitle}>Book a visit</div>

      <Tabs tabs={tabs} activeTab={activeTab} setActiveTab={setActiveTab}>
        {activeTab === 1 && (
          <div style={{ marginTop: "30px" }}>
            {options.length > 1 && (
              <>
                <div className={styles.selectContainer}>
                  <p className={styles.speciality}>Speciality:</p>
                  <Select
                    ref={selectRef}
                    options={options}
                    selectedProvider={specialtySelect}
                    onSelected={(value) => {
                      setSpecialitySelect(value);
                    }}
                    name="selectCondition"
                  />
                </div>
                <div className={styles.line}></div>
                <div style={{ marginBottom: "38px" }}></div>
              </>
            )}
            {renderTabView()}
          </div>
        )}
        <div style={{ marginTop: "30px" }}>{activeTab === 2 && renderTabView()}</div>
      </Tabs>
    </div>
  );
};
