// Keep in sync with server side
export const CarePlanItemCompletionStatus = {
  INCOMPLETE: 0,
  COMPLETE: 1,
  NOT_APPLICABLE: 2,
};

// Keep in sync with server side
export const CarePlanItemSubType = {
  CONTENT_MEDICATION: 1,
  CARE_TEAM: 2,
  BOOK_APPOINTMENT: 3,
  COACHING_CHECKIN: 4,
  CONTENT_EXERCISE: 5,
  BOOK_COACHING: 6,
  CONTENT_GENERIC: 7,
  TRACK_HEADACHES: 8,
  FOOD: 9,
  GENERIC_URL: 10,
  // Add more types here
};
