import React, { useState } from "react";
import OnboardingFormError from "../OnboardingFormError/OnboardingFormError";
import cn from "classnames";
import styles from "./OnboardingFormAgreement.module.scss";
import { ReactComponent as IconCheck } from "../../../../assets/icons/check.svg";

interface IOnboardingFormAgreement {
  field: any;
  form: any;
  label: any;
  children: any;
}

const OnboardingFormAgreement = (props: IOnboardingFormAgreement) => {
  const { field, form, label, children } = props;

  const [active, setActive] = useState(field.value);

  return (
    <div className={styles.wrapper}>
      <label className={cn("d-flex", styles.element)}>
        <input
          {...field}
          type="checkbox"
          onChange={(e) => {
            setActive(!active);
            form.setFieldValue(field.name, !active);
          }}
        />
        <span className={cn(styles.box, active && styles["isActive"])}>
          {active && <IconCheck />}
        </span>
        <span className={styles.label}>{children}</span>
      </label>
      {form.touched[field.name] && form.errors[field.name] && (
        <OnboardingFormError errors={form.errors} inputName={field.name} />
      )}
    </div>
  );
};

export default OnboardingFormAgreement;
