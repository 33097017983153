import React from "react";
import cn from "classnames";
import styles from "./OnboardingFormChoosePlan.module.scss";
import { ReactComponent as IconCheck } from "../../../../assets/icons/check.svg";

interface IOnboardingFormChoosePlan {
  field: any;
  form: any;
  label: any;
  children: any;
}

const OnboardingFormChoosePlan = (props: IOnboardingFormChoosePlan) => {
  const { field, form, label, children } = props;

  return (
    <div className={styles.wrapper}>
      <label className={cn("d-flex", styles.element)}>
        <input {...field} type="radio" />
        <span className={styles.label}>{children}</span>
        <span className={cn(styles.box, field.checked && styles["isActive"])}>
          {field.checked && <IconCheck />}
        </span>
      </label>
    </div>
  );
};

export default OnboardingFormChoosePlan;
