import Button from "../../../Button/Button";

import { conditions } from "../../../../utils/conditionFocus";
import { conditionType } from "../../../../types/condition";

import styles from "../OnboardingFormSelectionCondition.module.scss";

import { ReactComponent as HeadphonesIcon } from "../../../../assets/images/svg/headphones-human.svg";
import { ReactComponent as MoonIcon } from "../../../../assets/images/svg/moon-star.svg";
import { ReactComponent as WavesIcon } from "../../../../assets/images/svg/waves.svg";
import { ReactComponent as ConcussionIcon } from "../../../../assets/images/svg/concussion.svg";
import { ReactComponent as StrokeIcon } from "../../../../assets/images/svg/stroke.svg";
import { ReactComponent as TremorIcon } from "../../../../assets/images/svg/tremor.svg";
import { ReactComponent as UndiagnosedIcon } from "../../../../assets/images/svg/other_undiagnosed.svg";

interface IProps {
  type: conditionType;
  activeSelect: (option: conditionType) => void;
  handleSelect: (option: conditionType) => void;
  displayIcon: (option: conditionType) => void;
  activeTextButton: (option: conditionType) => void;
}

export const Card = ({
  type,
  activeSelect,
  handleSelect,
  displayIcon,
  activeTextButton,
}: IProps) => {
  const generateTitle = () => {
    switch (type) {
      case conditions.HEADACHE_MIGRAINE:
        return "Headache/Migraine";
      case conditions.SLEEP:
        return "Sleep";
      case conditions.EPILEPSY:
        return "Epilepsy";
      case conditions.CONCUSSION_TBI:
        return "Concussion/TBI";
      case conditions.STROKE:
        return "Stroke";
      case conditions.TREMOR:
        return "Tremor";
      case conditions.OTHER_UNDIAGNOSED:
        return "Other/Undiagnosed (Including Long Covid)";
    }
  };

  const generateDescription = () => {
    switch (type) {
      case conditions.HEADACHE_MIGRAINE:
        return `Our board-certified neurologists treat all headache disorders and migraine subtypes, no matter how rare.`;
      case conditions.SLEEP:
        return "Our board-certified sleep specialists treat all sleep disorders, including insomnia and sleep apnea.";
      case conditions.EPILEPSY:
        return "Our board-certified epilepsy specialists treat seizures and epilepsy.";
      case conditions.CONCUSSION_TBI:
        return "Our board-certified neurologists treat mild and moderate concussion and traumatic brain injury (TBI), including post-concussion syndrome.";
      case conditions.STROKE:
        return "Our board-certified neurologists treat Transient Ischemic Attack (TIA) and stroke patients as they recover, to avoid further episodes.";
      case conditions.TREMOR:
        return "Our board-certified neurologists treat essential tremor, tic, and cervical dystonia.";
      case conditions.OTHER_UNDIAGNOSED:
        return "Our board-certified neurologists can help diagnose neurological symptoms such as numbness, pain, dizziness and more.";
    }
  };

  const generateIcon = () => {
    switch (type) {
      case conditions.HEADACHE_MIGRAINE:
        return <HeadphonesIcon />;
      case conditions.SLEEP:
        return <MoonIcon />;
      case conditions.EPILEPSY:
        return <WavesIcon />;
      case conditions.CONCUSSION_TBI:
        return <ConcussionIcon />;
      case conditions.STROKE:
        return <StrokeIcon />;
      case conditions.TREMOR:
        return <TremorIcon />;
      case conditions.OTHER_UNDIAGNOSED:
        return <UndiagnosedIcon />;
    }
  };

  return (
    <div className={`${styles.wrapper__card} ${activeSelect(type)}`}>
      {generateIcon()}
      <div className={styles.wrapper__card__texts}>
        <h3 className={styles.heading}>{generateTitle()}</h3>
        <p className={styles.description}>{generateDescription()}</p>
      </div>

      <Button
        onClick={() => handleSelect(type)}
        className={`${styles.button}`}
        icon={displayIcon(type)}
        title={activeTextButton(type)}
        tag="button"
      />
    </div>
  );
};
