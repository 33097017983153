import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { BLOCKS, INLINES } from "@contentful/rich-text-types";
import classNames from "classnames";
import { createClient } from "contentful";
import { useEffect, useState } from "react";
import styles from "./BlogReader.module.scss";
import { firestore } from "../../config";
import { doc, getDoc } from "firebase/firestore";
import { CarePlanItemCompletionStatus } from "../../utils/carePlantUtil";
import { BlogReaderProps } from "../../views/HomePage/HomePage";
import { useUpdatePlanItemStatus } from "../../hooks/useUpdatePlanItemStatus";
import { IPatient } from "../../types/patient";
import Confetti from "react-dom-confetti";
import { confettiConfig } from "../../utils/confettiConfig";
import { ReactComponent as MarkDoneIcon } from "../../assets/icons/DailyCard/markDone.svg";
import Skeleton from "react-loading-skeleton";

interface IBlogProps {
  subItem: BlogReaderProps;
  patientData: IPatient;
}

const Text = ({ children }: any) => {
  const isFrame = children.some((el: any) => el.type === "iframe");

  return (
    <div className={classNames(styles.blogText, isFrame && styles.blogIframe)}>{children}</div>
  );
};

const options = {
  renderNode: {
    [BLOCKS.PARAGRAPH]: (_node: any, children: any) => <Text>{children}</Text>,

    [BLOCKS.HEADING_3]: (_node: any, children: any) => {
      return (
        <h3 className={styles.heading3}>{_node.content ? _node.content[0].value : children}</h3>
      );
    },

    [INLINES.HYPERLINK]: (node: any) => {
      if (node.data.uri.includes("youtube.com/embed")) {
        return (
          <iframe
            title="Neura Health"
            src={node.data.uri}
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            width="360"
            height="240"
            frameBorder="0"
            allowFullScreen
            style={{ textAlign: "center" }}
          />
        );
      } else {
        return <a href={node.data.uri}>{node.content[0].value}</a>;
      }
    },
    [BLOCKS.EMBEDDED_ASSET]: (node: { data: any }) => {
      const url = node.data.target.fields.file.url;
      if (url.startsWith("//videos")) {
        // Causes rendering issues if not wrapped in a <p>
        return (
          <p>
            <video src={`https:${node.data.target.fields.file.url}`} />
          </p>
        );
      } else {
        return (
          <img
            src={`https:${node.data.target.fields.file.url}`}
            alt={`https:${node.data.target.fields.file.url}`}
          />
        );
      }
    },

    [BLOCKS.LIST_ITEM]: (node: any, children: any) => {
      return (
        <li className={styles.blogList}>
          &#9679; <span>&nbsp;</span> <span>&nbsp;</span>
          {children[0].type?.name === "Text" ? <>{children[0].props.children}</> : children}
        </li>
      );
    },
  },
  renderText: (text: string) => {
    return (
      <span>{text.split("\n").flatMap((text: any, i: number) => [i > 0 && <br />, text])}</span>
    );
  },
};

export default function BlogReader({
  subItem: { status, contentID, uniqueID },
  patientData,
}: IBlogProps) {
  const [contentEntry, setContentEntry] = useState<any>();
  const [isDone, setIsDone] = useState(status);

  const { updatePlanItemStatus, explosion } = useUpdatePlanItemStatus(patientData);

  const { COMPLETE, INCOMPLETE, NOT_APPLICABLE } = CarePlanItemCompletionStatus;

  const fetchContentfulData = async () => {
    const docRef = doc(firestore, "gezunt_keys", "contentful");
    getDoc(docRef).then((querySnapshot) => {
      const contentfulDataDoc = querySnapshot.data();
      const client = createClient({
        space: contentfulDataDoc?.space,
        accessToken: contentfulDataDoc?.token,
      });

      client
        .getEntry(`${contentID}`)
        .then((entry: any) => {
          setContentEntry(entry);
        })
        .catch((err) => console.log(err));
    });
  };

  const handleMarkAsDone = (e: React.MouseEvent<HTMLInputElement>) => {
    updatePlanItemStatus(e, uniqueID, isDone);
    setIsDone((prevState) => (prevState === INCOMPLETE ? COMPLETE : INCOMPLETE));
  };

  useEffect(() => {
    fetchContentfulData();
  }, [contentID]);

  const renderDocument = () => documentToReactComponents(contentEntry?.fields.body, options);

  return (
    <>
      <Confetti active={explosion} config={confettiConfig} />
      <div className={styles.blog}>
        <div>
          {!renderDocument() ? (
            <div className={styles.wrapperSkeleton}>
              <Skeleton count={5} height={100} width={400} style={{ marginBottom: "10px" }} />
            </div>
          ) : (
            <>
              <div className={styles.blogTitle}>{contentEntry?.fields.title}</div>
              {renderDocument()}
            </>
          )}
        </div>
      </div>
      {status !== NOT_APPLICABLE && (
        <div className={styles.mark} onClick={handleMarkAsDone}>
          {isDone === INCOMPLETE && <p className={styles.markAsDone}>Mark as Done</p>}
          {isDone === COMPLETE && (
            <p className={styles.done}>
              <MarkDoneIcon /> Done
            </p>
          )}
        </div>
      )}
    </>
  );
}
