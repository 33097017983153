import React, { useEffect, useRef } from "react";
import styles from "./ModalLayout.module.scss";
import { ReactComponent as Cancel } from "../../assets/images/svg/cancel.svg";

type Prop = {
  children: React.ReactNode;
  handleClose?: () => void;
  style?: any;
};

export default function ModalLayout({ children, handleClose, style }: Prop) {
  const node = useRef<any>(null);

  useEffect(() => {
    function handleClickOutside(event: any) {
      if (node.current && !node.current.contains(event.target)) {
        handleClose && handleClose();
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [handleClose, node]);

  return (
    <div className={styles.overlay}>
      <div className={styles.modal} style={style} ref={node}>
        <button className={styles.close} onClick={handleClose}>
          <Cancel />
        </button>
        {children}
      </div>
    </div>
  );
}
