import Button from "../Button/Button";
import Heading from "../Heading/Heading";
import Modal from "../Modal/Modal";
import styles from "./UpgradePaymentsInfoModal.module.scss";

export const UpgradePaymentsInfoModal = ({ handleClose }: { handleClose: () => void }) => {
  return (
    <>
      <Heading size="h3" title="Upgrade to book a visit" />
      <p className={styles.typography}>
        You are currently on the Maintenance Plan. For medical questions or to request refills,
        please message us or email{" "}
        <a href="mailto:support@neurahealth.co">support@neurahealth.co</a>
      </p>
      <p className={styles.typography}>
        If you would like to upgrade to book a visit, our support team can also help you with that.
      </p>

      <Button onClick={handleClose} className={styles.button} tag="button" title="Done" />
    </>
  );
};
