export const partnersName: any = {
  // key should be write by uppercase.
  NEURA_SLEEP: "neura_sleep",
  TEST_INTERNAL: "test_internal",
  HEALTHVANA: "healthvana",
  COVET: "covet",
  SANA: "sana",
  MARPAI: "marpai",
  CENTIVO: "centivo",
  JEFFERSON: "jefferson",
  CBTI: "cbti",
  OMNI: "omni",
  EMORY: "emory",
  RIVER_HEALTH: "riverhealth",
  KOCH: "koch",
  THERANICA: "theranica",
  CTG: "ctg",
  DAVIS_BOXING_PROMOTIONS: "davisboxingpromotions",
};

export const partnersLabel: any = {
  NEURA_SLEEP: "Neura Sleep",
  TEST_INTERNAL: "Test Internal",
  HEALTHVANA: "Healthvana",
  COVET: "Covet",
  SANA: "Sana",
  MARPAI: "Marpai",
  CENTIVO: "Centivo",
  JEFFERSON: "Jefferson",
  CBTI: "CBT-I",
  OMNI: "Trio",
  EMORY: "Emory",
  RIVER_HEALTH: "River Health",
  KOCH: "Koch Industries",
  THERANICA: "Theranica",
  CTG: "Concussion Therapy Group",
  DAVIS_BOXING_PROMOTIONS: "Davis Boxing Promotions",
};
