import React from "react";
import { NavLink } from "react-router-dom";
import styles from "./Button.module.scss";
import cn from "classnames";

interface IButton {
  tag: "a" | "button";
  title: React.ReactNode;
  disabled?: boolean;
  to?: string;
  icon?: React.ReactNode;
  className?: string;
  variant?: "alternate";
  onClick?: () => void;
  type?: "button" | "submit" | "reset";
}

const Button = (props: IButton) => {
  const {
    tag,
    title,
    to = "/",
    icon,
    className,
    variant,
    onClick,
    disabled = false,
    type = "button",
  } = props;

  if (tag === "a") {
    return (
      <NavLink
        onClick={onClick}
        className={cn(
          styles.element,
          "d-inline-flex justify-content-center",
          className,
          variant === "alternate" && styles["isAlternate"]
        )}
        exact
        to={{ pathname: to, search: window.location.search }}
      >
        {title}
      </NavLink>
    );
  }
  return (
    <button
      onClick={onClick}
      className={cn(
        styles.element,
        "d-inline-flex justify-content-center",
        className,
        variant === "alternate" && styles["isAlternate"],
        disabled && styles["disabled"]
      )}
      disabled={disabled}
      type={type}
    >
      {icon}
      {title}
    </button>
  );
};
export default Button;
