import firebase from "firebase/compat/app";
import "firebase/compat/functions";

interface IFirebaseFunctions {
  updateHubspotContact(eventName: string, data: {}): any;
  createCustomerAndSubscribe(data: customerData): any;
  createCustomerPortalSession(data: createCustomerPortalSessionData): Promise<string>;
  sendSMSWithLinkToApp(patientId: string | number): Promise<sendSMSWithLinkToAppData>;
  applyPromotionCode(data: applyPromotionCodeT): Promise<applyPromotionCodeResponseT>;
  getNextFewInitialVisitTimes(): Promise<Object>;
  cancelSubscription(data: cancelSubscriptionType): any;
  activateMember(data: activateMemberType): any;
}

type cancelSubscriptionType = {
  patientID: string;
  reason: string;
  reasonValue: number;
  feedback?: string;
  wantsALaCarte: boolean;
  wantsMessagingOnly?: boolean;
};
type sendSMSWithLinkToAppData = { success: boolean; error: string };
type createCustomerPortalSessionData = { patientID: string; isTest: boolean };
type applyPromotionCodeT = {
  patientID: string;
  isTest: boolean;
  subscriptionID: string;
  code: string;
};

type applyPromotionCodeResponseT = {
  error: null | string;
  success: boolean;
  userExplanation?: string;
};

type customerData = {
  patientID: string | number;
  email: string;
  isTest: boolean;
  referral: string | null;
  couponID: string | null;
};
type activateMemberType = { partner: string; patientID: string };
type recommendedVisits = { patientID: string };
export type IRequestRefill = {
  patientID: string;
  medicationName: string;
  pharmacyName: string;
  pharmacyAddress: string;
};
class FirebaseFunctions implements IFirebaseFunctions {
  private functions: firebase.functions.Functions;

  constructor() {
    this.functions = firebase.functions();
  }

  async sendSMSWithLinkToApp(patientId: string | number) {
    const { data } = await this.functions.httpsCallable("sendSMSWithLinkToApp")({
      patientID: patientId,
    });

    return data;
  }

  async loadPatientLandingPage(email: string) {
    const response = await this.functions.httpsCallable("loadPatientLandingPage")({
      patientEmail: email,
    });

    return response?.data?.landingPage;
  }

  async updateHubspotContact(eventName: string, data: {}) {
    return await this.functions.httpsCallable("updateHubspotContact")({
      eventName: eventName,
      ...data,
    });
  }

  async getJWTToken(patientID: string) {
    const tokenData = await this.functions.httpsCallable("getJWTToken")({
      patientID: patientID,
    });
    return tokenData.data;
  }

  createCustomerAndSubscribe(data: customerData) {
    return this.functions.httpsCallable("createCustomerAndSubscribeV4")(data);
  }

  async assignCareTeamByCondition(patientID: string) {
    await this.functions.httpsCallable("assignCareTeamByCondition")({
      patientID: patientID,
    });
  }

  async getNextFewInitialVisitTimes() {
    const response = await this.functions.httpsCallable("getNextFewInitialVisitTimes")();
    return response?.data ?? {};
  }

  async createCustomerPortalSession(data: createCustomerPortalSessionData) {
    const response = await this.functions.httpsCallable("createCustomerPortalSession")(data);

    return response?.data?.sessionURL;
  }

  async createHubspotContact(email: string) {
    await this.functions.httpsCallable("createHubspotContact")({ email });
  }

  async applyPromotionCode(data: applyPromotionCodeT) {
    const response = await this.functions.httpsCallable("applyPromotionCode")(data);
    return response.data;
  }

  async cancelSubscription(data: cancelSubscriptionType) {
    const response = await this.functions.httpsCallable("cancelSubscription")(data);
    return response.data;
  }

  async activateMember(data: activateMemberType) {
    const response = await this.functions.httpsCallable("activateMember")(data);
    return response.data;
  }

  async fetchRecommendedVisits(data: recommendedVisits) {
    const response = await this.functions.httpsCallable("getRecommendedAppointmentForPatient")(
      data
    );
    return response.data;
  }

  async requestRefill(data: IRequestRefill) {
    return await this.functions.httpsCallable("requestRefill")(data);
  }

  async getPatientFormList(data: { patientID: string }) {
    return await this.functions.httpsCallable("getPatientFormList")(data);
  }
  async checkForDuplicateAccounts(data: { patientID: string }) {
    return await this.functions.httpsCallable("checkForDuplicateAccounts")(data);
  }
  async getBannerContent() {
    return await this.functions.httpsCallable("getSystemMessage")();
  }
}

export default FirebaseFunctions;
