import OnboardingFormSectionAddress from "../OnboardingFormSectionAddress/OnboardingFormSectionAddress";
import OnboardingFormSectionMedicalPersonal from "../OnboardingFormSectionMedicalPersonal/OnboardingFormSectionMedicalPersonal";
import OnboardingFormSectionPersonalData from "../OnboardingFormSectionPersonalData/OnboardingFormSectionPersonalData";
import OnboardingFormSelectionCondition from "../OnboardingFormSelectionCondition/OnboardingFormSelectionCondition";

import { IOnboardingForm } from "../OnboardingForm";

import { IFormData, ICoupon } from "../OnboardingFormSection.interface";
import { useHistory } from "react-router";
import OnboardingFormSectionRegister from "../OnboardingFormSectionRegister/OnboardingFormSectionRegister";
import { OnboardingFormCreatePassword } from "../OnboardingFormCreatePassword/OnboardingFormCreatePassword";

import { useDefaultPasswordUpdate } from "../../../hooks/useDefaultPasswordUpdate";
import { useEffect } from "react";
import { useOnboardingStepCountContext } from "../../../context/onboardingStepCount";

interface IProps extends IOnboardingForm {
  formData?: IFormData;
  setFormData: (formData: any) => void;
  setFirstStep: () => void;
  coupon: ICoupon | null;
  localGenPw?: string | null;
  setLocalGenPw: (pwd: string) => void;
}

export const B2bFlow = ({
  uid,
  patientData,
  userEmail,
  formData,
  setFormData,
  step,
  setStep,
  setFirstStep,
  setLoading,
  partner,
  coupon,
  loginMessage,
  setLoginMessage,
  localGenPw,
  setLocalGenPw,
}: IProps) => {
  const { defaultPasswordUpdated, fetchDefaultPasswordUpdate } = useDefaultPasswordUpdate();

  const { setStepCount } = useOnboardingStepCountContext();
  const history = useHistory();

  const ComponentsProps = {
    uid,
    patientData,
    userEmail,
    formData,
    setLoading,
    setFormData,
    step,
    setFirstStep,
    loginMessage,
    setLoginMessage,
    localGenPw,
  };

  useEffect(() => {
    if (!uid) return;
    fetchDefaultPasswordUpdate(uid);
  }, [uid, step]);

  useEffect(() => {
    if (defaultPasswordUpdated) setStepCount(4);
    else setStepCount(5);
  }, [defaultPasswordUpdated]);

  switch (step) {
    default:
      return (
        <OnboardingFormSectionRegister
          setStep={setStep}
          setNextStep={() => setStep(1)}
          loginMessage={loginMessage}
          formData={formData}
          setFormData={setFormData}
          step={step}
          partner={partner}
          setLocalGenPw={setLocalGenPw}
        />
      );
    case 1:
      return (
        <OnboardingFormSectionPersonalData
          setNextStep={() => setStep(2)}
          partner={partner}
          {...ComponentsProps}
        />
      );

    case 2:
      return (
        <OnboardingFormSelectionCondition
          setNextStep={() => setStep(3)}
          partner={partner}
          {...ComponentsProps}
        />
      );
    case 3:
      return (
        <OnboardingFormSectionAddress
          setNextStep={() => setStep(4)}
          partner={partner}
          {...ComponentsProps}
        />
      );
    case 4:
      if (!defaultPasswordUpdated)
        return (
          <OnboardingFormCreatePassword
            setNextStep={() => setStep(5)}
            partner={partner}
            {...ComponentsProps}
          />
        );

      return (
        <OnboardingFormSectionMedicalPersonal
          setNextStep={() => setStep(6)}
          partner={partner}
          coupon={coupon}
          {...ComponentsProps}
        />
      );
    case 5:
      return (
        <OnboardingFormSectionMedicalPersonal
          setNextStep={() => setStep(6)}
          partner={partner}
          coupon={coupon}
          {...ComponentsProps}
        />
      );
    case 6:
    case 7:
      history.push("/home");
      return <></>;
  }
};
