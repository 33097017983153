import { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import amplitude from "amplitude-js";

import styles from "./OnboardingFormSelectionCondition.module.scss";

import OnboardingFormHeading from "../OnboardingFormComponents/OnboardingFormHeading/OnboardingFormHeading";
import NumberOfStep from "../../NumberOfStep/NumberOfStep";
import OnboardingFormSubmit from "../OnboardingFormComponents/OnboardingFormSubmit/OnboardingFormSubmit";
import DescriptionInfo from "../../DescriptionInfo/DescriptionInfo";
import { Card } from "./Card/Card";

import sessionCheck from "../../../helpers/sessionCheck";
import { conditions } from "../../../utils/conditionFocus";
import { IOnboardingFormSection } from "../OnboardingFormSection.interface";
import { conditionType } from "../.././../types/condition";
import FirebaseApi from "../../../api/FirebaseApi/FirebaseApi";

import { ReactComponent as CheckmarkIcon } from "../../../assets/icons/checkmark-sharp.svg";
import { ReactComponent as BackgroundLeftIcon } from "../../../assets/icons/condition_icon_left.svg";
import { ReactComponent as BackgroundRightIcon } from "../../../assets/icons/condition_icon_right.svg";
import { isNotExcludedFromTracking } from "../../../helpers/isNotExcludedFromTracking";
import { useGATrackingForSpecificPartner } from "../../../hooks/useGATrackingForSpecificPartner";
import { partnersName } from "../../../utils/partnerList";

const OnboardingFormSelectionCondition = ({
  formData,
  userEmail,
  setFormData,
  setNextStep,
  setFirstStep,
  setLoginMessage,
  step,
  uid,
  patientData,
  partner,
}: IOnboardingFormSection) => {
  const initSelectValue = formData?.conditionFocus
    ? formData.conditionFocus
    : patientData?.conditionFocus
    ? patientData?.conditionFocus
    : [];
  const [conditionFocus, setConditionFocus] = useState<conditions[]>([]);
  const [select, setSelect] = useState<conditions[]>(initSelectValue);
  const [isLoading, setIsLoading] = useState(false);

  const history = useHistory();

  const { runTracking } = useGATrackingForSpecificPartner();

  const findItem = (option: conditionType) => select.find((item) => item === option);

  const activeSelect = (option: conditionType) => findItem(option) && styles.active;

  const activeTextButton = (option: conditionType): string => {
    const foundItem = findItem(option);
    return foundItem ? "Selected" : "Select";
  };

  const displayIcon = (option: conditionType) => {
    const foundItem = findItem(option);
    if (foundItem) return <CheckmarkIcon />;
  };

  const handleSelect = (option: conditionType) => {
    setSelect([option]);
  };

  const handleSubmit = async () => {
    const session = await sessionCheck(step, setFirstStep, setLoginMessage, history);
    setIsLoading(true);

    if (!session) {
      setIsLoading(false);
      return;
    }

    setFormData({ ...formData, conditionFocus: select });

    const firestore = new FirebaseApi().firebaseFirestore();

    const data = {
      uid: formData?.uid || uid || patientData?.uid,
      conditionFocus: select,
      primaryConditionFocus: select[0],
    };

    await firestore.updatePatient({ data });

    amplitude.getInstance().logEvent("onboarding_v2_condition_focus", {
      conditionFocus: select,
    });

    const functions = new FirebaseApi().firebaseFunctions();

    functions
      .assignCareTeamByCondition(formData?.uid || uid || patientData?.uid)
      .then(() => {
        setNextStep();
      })
      .finally(() => {
        setIsLoading(false);
      });

    try {
      functions.updateHubspotContact("condition_focus", {
        email: formData?.email || patientData?.email || userEmail,
        eventValue: select.join(";"),
      });
      functions.updateHubspotContact("condition_count", {
        email: formData?.email || patientData?.email || userEmail,
        eventValue: select.length,
      });

      // This event is for one specific partner, Theranica
      runTracking(partnersName.THERANICA, "neura_condition-focus");

      isNotExcludedFromTracking(formData?.email || patientData?.email || userEmail, () => {
        // @ts-ignore
        window?.dataLayer?.push({
          event: "onboarding_v2_condition_focus",
        });
      });
    } catch (_error) {
      // TODO: send error in Sentry.
    }
  };

  useEffect(() => {
    const firestore = new FirebaseApi().firebaseFirestore();
    firestore.getConditionForPartner().then((data) => {
      if (!data) return;
      const condition = Object.keys(data?.conditions).find((key) => {
        if (!partner) return key === "d2c";
        return key === partner;
      });

      const conditionsPartner = condition
        ? data?.conditions[`${condition}`]
        : data?.conditions["default"];

      setConditionFocus(conditionsPartner);
    });
  }, [partner]);

  useEffect(() => {
    if (conditionFocus.length === 1) setSelect(conditionFocus);
  }, [conditionFocus]);

  return (
    <>
      <BackgroundLeftIcon className={styles.BackgroundLeftIcon} />
      <BackgroundRightIcon className={styles.BackgroundRightIcon} />
      <OnboardingFormHeading
        className={styles.heading}
        title="Please select your primary concern"
      />
      {conditionFocus.length > 1 && (
        <DescriptionInfo
          text="We'll form a personalized care team for your specific needs."
          className={styles.subHeading}
        />
      )}
      <div className={styles.wrapper}>
        {conditionFocus.map((conditionFocus) => (
          <Card
            key={conditionFocus}
            type={conditionFocus as conditionType}
            activeSelect={activeSelect}
            handleSelect={handleSelect}
            displayIcon={displayIcon}
            activeTextButton={activeTextButton}
          />
        ))}
      </div>
      <div className={`${styles.nextButton} d-flex justify-content-center`}>
        <OnboardingFormSubmit
          onClick={handleSubmit}
          isSubmitting={isLoading}
          title="Next"
          disabled={select.length === 0}
        />
      </div>
      <NumberOfStep step={step} />
    </>
  );
};

export default OnboardingFormSelectionCondition;
