import styles from "../ResourcesCard/ResourcesCard.module.scss";
import { documentToHtmlString } from "@contentful/rich-text-html-renderer";

interface IProps {
  title: string;
  image: string;
  description: any;
  authorName: string;
  options: any;
  handleClick: (data: any) => void;
}

export const ResourcesContentModal = ({
  title,
  image,
  description,
  authorName,
  options,
  handleClick,
}: IProps) => {
  return (
    <div className={styles.rootMobile}>
      <div className={styles.rootMobile__image}>
        <img src={image} />
      </div>
      <div className={styles.rootMobile__content}>
        <h3 className={styles.heading}>{title}</h3>
        <p className={styles.authorName}>{authorName}</p>
        <h3
          className={styles.descriptoon}
          onClick={() => handleClick({ title, image, description })}
        >
          {
            <div
              dangerouslySetInnerHTML={{
                __html: documentToHtmlString(description, options),
              }}
            />
          }
        </h3>
      </div>
    </div>
  );
};
