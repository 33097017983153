import { useCallback, useEffect, useState } from "react";
import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
  useElements,
} from "@stripe/react-stripe-js";
import amplitude from "amplitude-js";
import cn from "classnames";
import { Field, Form, Formik } from "formik";
import * as Yup from "yup";
import { useHistory } from "react-router-dom";
import OnboardingFormHeading from "../OnboardingFormComponents/OnboardingFormHeading/OnboardingFormHeading";
import Grid, { GridElem } from "../../Grid/Grid";
import OnboardingFormSubmit from "../OnboardingFormComponents/OnboardingFormSubmit/OnboardingFormSubmit";
import OnboardingFormPaymentInput from "../OnboardingFormComponents/OnboardingFormPaymentInput/OnboardingFormPaymentInput";
import { IOnboardingFormSection } from "../OnboardingFormSection.interface";
import { IOnboardingForm } from "../OnboardingForm";
import OnboardingFormError from "../OnboardingFormComponents/OnboardingFormError/OnboardingFormError";
import OnboardingFormTextInput from "../OnboardingFormComponents/OnboardingFormTextInput/OnboardingFormTextInput";
import colors from "../../../assets/styles/variables/colors.module.scss";
import styles from "./OnboardingFormSectionPayment.module.scss";
import sessionCheck from "../../../helpers/sessionCheck";
import OnboardingFormDiscountCode from "../OnboardingFormComponents/OnboardingFormDiscountCode/OnboardingFormDiscountCode";
import ONBOARDING_PAYMENT_DATA from "../../../helpers/OnboardingFormSectionPaymentData";
import { ReactComponent as Decoration } from "../../../assets/images/svg/onboardingSectionPayment/onboarding-payment-details.svg";
import NumberOfStep from "../../NumberOfStep/NumberOfStep";
import statsig from "statsig-js";
import { isNotExcludedFromTracking } from "../../../helpers/isNotExcludedFromTracking";

interface IOnboardingFormSectionPayment extends IOnboardingFormSection {
  userEmail: IOnboardingForm["userEmail"];
  uid?: IOnboardingForm["uid"];
  stripe?: any;
  stripePatientData: any;
  formData: any;
  patientData: any;
  setStep: any;
  step: number;
  setLoginMessage: any;
  smallScreen?: boolean;
  coupon?: any;
}

const OnboardingFormSectionPayment = ({
  stripe,
  setNextStep,
  setFirstStep,
  stripePatientData,
  patientData,
  userEmail,
  formData,
  step,
  uid,
  smallScreen,
  setLoginMessage,
  coupon,
}: IOnboardingFormSectionPayment) => {
  const history = useHistory();
  const [paymentLoading, setPaymentLoading] = useState(false);
  const [elements] = useState(useElements());
  const [errorMessage, setErrorMessage] = useState("null");
  const [cardData, setCardData] = useState({
    card: elements?.getElement(CardNumberElement),
    exp: elements?.getElement(CardExpiryElement),
    cvs: elements?.getElement(CardCvcElement),
  });
  const [nextFewApptsData, setNextFewApptsData] = useState({});
  const [loadingNextFewAppts, setLoadingNextFewAppts] = useState(true);

  const [stripeElementsErrors, setStripeElementsErrors] = useState({
    cardNumber: "initial",
    cvs: "initial",
    expiryDate: "initial",
  });

  const setWatchers = useCallback(
    function watchers() {
      if (cardData.card) {
        cardData.card?.on("change", (event) => {
          if (event.error) {
            setStripeElementsErrors((prevState) => ({
              ...prevState,
              //@ts-ignore
              cardNumber: event.error.message,
            }));
          } else {
            setStripeElementsErrors((prevState) => ({
              ...prevState,
              cardNumber: "",
            }));
          }
        });

        cardData?.exp?.on("change", (event) => {
          if (event.error) {
            setStripeElementsErrors((prevState) => ({
              ...prevState,
              //@ts-ignore
              expiryDate: event.error.message,
            }));
          } else {
            setStripeElementsErrors((prevState) => ({
              ...prevState,
              expiryDate: "",
            }));
          }
        });

        cardData.cvs?.on("change", (event) => {
          if (event.error) {
            setStripeElementsErrors((prevState) => ({
              ...prevState,
              //@ts-ignore
              cvs: event.error.message,
            }));
          } else {
            setStripeElementsErrors((prevState) => ({ ...prevState, cvs: "" }));
          }
        });
      }
    },
    [cardData]
  );

  useEffect(() => {
    if (!cardData.card) {
      setCardData({
        card: elements?.getElement(CardNumberElement),
        exp: elements?.getElement(CardExpiryElement),
        cvs: elements?.getElement(CardCvcElement),
      });
    }

    setWatchers();
  }, [cardData, elements, setWatchers]);

  useEffect(() => {
    statsig
      .initialize("client-OhJ3qoq4eAexLRj8rsVBaBW8epJlD8w0ZwBgrHTz69P", {
        userID: uid || formData.uid || patientData.uid,
      })
      .then(async (_statsigInitialized) => {
        // const expConfig = statsig.getExperiment(
        //   "show_appointments_on_cc_screen"
        // );

        // const showAppointments = expConfig.get("show_appointments", false);
        // if (showAppointments) {
        //   const functions = new FirebaseApi().firebaseFunctions();
        //   const nextFewAppts = await functions.getNextFewInitialVisitTimes();
        //   setNextFewApptsData(nextFewAppts);
        // }
        setLoadingNextFewAppts(false);
      });
  }, []);

  const style = {
    base: {
      color: colors["primary"],
      lineHeight: "30px",
      fontWeight: 400,
      fontSize: "16px",

      "::placeholder": {
        color: "rgba(91, 68, 60, 0.6)",
      },
    },
  };

  const getReadableDate = (dateYMDDash: string) => {
    const d = new Date(dateYMDDash);
    return d.toLocaleString("default", { month: "short", day: "2-digit" });
  };

  const getReadableTime = (timeString: string) => {
    const d = new Date(timeString);
    return d.toLocaleString("default", {
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    });
  };

  const logValidSubmitEvent = () => {
    amplitude.getInstance().logEvent("onboarding_v2_credit_card_valid");

    isNotExcludedFromTracking(userEmail, () => {
      // @ts-ignore
      window?.dataLayer.push({
        event: "onboarding_v2_credit_card_valid",
      });
    });

    statsig.logEvent("onboarding_v2_credit_card_valid");
  };

  const getTitleLineForPlan = (planName: string) => {
    return planName === "quarterly"
      ? "$35/month after that"
      : planName === "annual"
      ? "$29/month after that"
      : "$39/month after that";
  };

  const getDescriptionLineForPlan = (planName: string) => {
    return planName === "quarterly"
      ? "Billed as $105 for 3 months"
      : planName === "annual"
      ? "Billed as $348 for 12 months"
      : "Cancel anytime";
  };

  const onFormSubmit = useCallback(
    async (values: any) => {
      setPaymentLoading(true);
      if (!(await sessionCheck(step, setNextStep, setLoginMessage, history))) {
        return;
      }

      amplitude.getInstance().logEvent("onboarding_v2_credit_card_submit");

      isNotExcludedFromTracking(userEmail, () => {
        // @ts-ignore
        window?.dataLayer.push({
          event: "onboarding_v2_credit_card_submit",
        });
      });

      try {
        const data = await stripe.confirmCardPayment(stripePatientData?.paymentIntentSecret, {
          payment_method: {
            card: cardData.card,
            billing_details: {
              name: values.name,
              address: {
                postal_code: values.zip,
              },
            },
          },
        });

        if (data?.error) {
          throw new Error(data?.error?.message);
        }
        if (window.hasOwnProperty("ReactNativeWebView")) {
          // @ts-ignore
          window?.ReactNativeWebView.postMessage(
            JSON.stringify({ eventType: "completedRegistration" })
          );
        }

        logValidSubmitEvent();
        history.push({ pathname: "/thankyou", search: window.location.search });
      } catch (error) {
        if (
          // @ts-ignore
          error?.message ===
          "You have an in-flight confirmCardPayment! Please be sure to disable your form submit button when confirmCardPayment is called."
        ) {
          return;
        }
        // @ts-ignore
        setErrorMessage(error?.message);
        setPaymentLoading(false);
        return;
      }
    },
    /* eslint react-hooks/exhaustive-deps: "off"*/
    [
      cardData,
      setFirstStep,
      stripePatientData?.setupIntentSecret,
      stripe,
      stripePatientData?.paymentIntentSecret,
    ]
  );

  return (
    <>
      <Formik
        initialValues={{
          name:
            (formData?.firstName || patientData?.firstName) +
            " " +
            (formData?.lastName || patientData?.lastName),
          zip: "",
        }}
        onSubmit={onFormSubmit}
        validationSchema={Yup.object().shape({
          zip: Yup.string()
            .matches(/^[0-9]+$/, "Must be only digits.")
            .length(5, "ZIP Code must be valid.")
            .required("ZIP Code is required."),
          name: Yup.string().required("Name is required."),
        })}
        validateOnChange
      >
        {({ isValid, values }) => (
          <>
            <div className={cn(styles.heading, "d-flex justify-content-center")}>
              <OnboardingFormHeading
                title={
                  nextFewApptsData && Object.keys(nextFewApptsData).length > 0
                    ? "Complete signup to book a visit with a neurologist"
                    : !loadingNextFewAppts
                    ? `Your virtual neurology clinic membership includes:`
                    : ""
                }
              />
            </div>

            {nextFewApptsData && Object.keys(nextFewApptsData).length > 0 ? (
              <div className={cn(styles.visitTimes)}>
                <p>Here are some available times:</p>
                <table>
                  {Object.entries(nextFewApptsData).map((nextApptEntry, index) => (
                    <tr key={index}>
                      <th>{getReadableDate(nextApptEntry[0])}</th>
                      {Array.isArray(nextApptEntry[1]) &&
                        nextApptEntry[1].map((dayAppt) => <td>{getReadableTime(dayAppt)}</td>)}
                    </tr>
                  ))}
                </table>
              </div>
            ) : !loadingNextFewAppts ? (
              <div className={cn(styles.list)}>
                {ONBOARDING_PAYMENT_DATA.map((element) => (
                  <div className={cn(styles.item, "d-flex align-items-center")}>
                    <img src={element.image} alt="" />

                    <p>
                      {element.name}
                      <sup>{element.sup ?? ""}</sup>
                    </p>
                  </div>
                ))}
              </div>
            ) : (
              <div />
            )}

            <div className={cn(styles.formContainer)}>
              <Decoration className={styles.decoration} />

              <Form id="payment_details" className={cn("d-flex flex-column", styles.formHack)}>
                <div
                  className={cn("d-flex flex-column", styles.form, {
                    [styles.formBackground]: !smallScreen,
                  })}
                >
                  <div className={styles.formHeader}>
                    <p>
                      {patientData?.lastSubscriptionCancellationDate
                        ? "$39/month"
                        : "$1 for the first week"}
                    </p>
                  </div>

                  <Grid className={styles.paymentDetails} rowGap={0}>
                    <GridElem className={cn(styles.paymentHeader, "text-center")}>
                      <p>
                        {patientData?.lastSubscriptionCancellationDate
                          ? "Billed monthly"
                          : getTitleLineForPlan(formData?.planName || patientData?.planName)}
                      </p>

                      {!patientData?.lastSubscriptionCancellationDate && (
                        <div className={styles.billingText}>
                          {getDescriptionLineForPlan(formData?.planName || patientData?.planName)}
                        </div>
                      )}
                    </GridElem>

                    <GridElem>
                      <OnboardingFormPaymentInput
                        label="Credit Card Number"
                        inputName="cardNumber"
                        errors={stripeElementsErrors}
                        component={
                          <CardNumberElement
                            options={{
                              style: style,
                            }}
                          />
                        }
                      />
                    </GridElem>

                    <GridElem size={6}>
                      <OnboardingFormPaymentInput
                        label="Expiry Date"
                        inputName="expiryDate"
                        errors={stripeElementsErrors}
                        component={
                          <CardExpiryElement
                            options={{
                              style: style,
                            }}
                          />
                        }
                      />
                    </GridElem>

                    <GridElem size={6}>
                      <OnboardingFormPaymentInput
                        inputName="cvs"
                        errors={stripeElementsErrors}
                        label="CVC"
                        component={
                          <CardCvcElement
                            options={{
                              style: style,
                            }}
                          />
                        }
                      />
                    </GridElem>

                    <GridElem>
                      <Field label="Name" name="name" component={OnboardingFormTextInput} />
                    </GridElem>

                    <GridElem size={6} md={5}>
                      <Field label="ZIP Code" name="zip" component={OnboardingFormTextInput} />
                    </GridElem>
                  </Grid>

                  {!patientData?.lastSubscriptionCancellationDate && (
                    <GridElem>
                      <OnboardingFormDiscountCode
                        label="Discount code"
                        formData={formData}
                        uid={uid}
                        coupon={coupon}
                      />
                    </GridElem>
                  )}

                  <GridElem className={cn({ hidden: errorMessage === "null" })}>
                    <OnboardingFormError
                      errors={{ [errorMessage]: errorMessage }}
                      inputName={errorMessage}
                      className={cn(styles.errorMessage, {
                        [styles.zeroOpacity]: errorMessage === "null",
                      })}
                    />
                  </GridElem>
                </div>
                <GridElem className={styles.priceText}>
                  <p className={cn("text-center")}>
                    <b>
                      <sup>*</sup>Video Visit Fees
                    </b>
                  </p>
                  <p className={cn("text-center")}>First Visit: $199 or copay</p>
                  <p className={cn("text-center")}>Follow Up Visits: $144 or copay</p>
                </GridElem>
                <GridElem className={styles.consentText}>
                  <p className={cn("text-center")}>
                    By clicking below you are consenting to being charged for your first week of
                    membership and then {formData?.planName || patientData?.planName}. You may
                    cancel at any time by contacting your care team from the Neura app.
                  </p>
                </GridElem>
                <div
                  className={cn(
                    styles.buttonWrapper,
                    "d-flex justify-content-center mt-large",
                    styles.paddingBottom
                  )}
                >
                  <OnboardingFormSubmit
                    onClick={onFormSubmit}
                    title={
                      patientData?.lastSubscriptionCancellationDate
                        ? "Join Neura"
                        : "Join Neura for $1"
                    }
                    isSubmitting={paymentLoading}
                    disabled={
                      paymentLoading ||
                      !(
                        values.zip.length === 5 &&
                        isValid &&
                        stripeElementsErrors.cardNumber === "" &&
                        stripeElementsErrors.cvs === "" &&
                        stripeElementsErrors.expiryDate === ""
                      )
                    }
                  />
                </div>
                <div
                  className={cn(
                    styles.buttonWrapperEnrollment,
                    "d-flex justify-content-center mt-small",
                    {
                      [styles.paddingBottomEnrollment]: !smallScreen,
                    }
                  )}
                >
                  <a href={`https://neurahealth.co/enrollment`} rel="noreferrer" target="_blank">
                    <button
                      onClick={() => {}}
                      className={cn(styles.enrollmentButton)}
                      type="button"
                    >
                      Questions? Speak With Us
                    </button>
                  </a>
                </div>
                <NumberOfStep step={step} />
              </Form>
            </div>
          </>
        )}
      </Formik>
    </>
  );
};
export default OnboardingFormSectionPayment;
