import { useEffect, useState } from "react";
import { Redirect, Route, Switch, useHistory, useLocation } from "react-router-dom";
import amplitude from "amplitude-js";
import { Helmet } from "react-helmet";
import { hotjar } from "react-hotjar";
import { FirebaseAuthConsumer } from "@react-firebase/auth";
import TagManager from "react-gtm-module";
import ReactPixel from "react-facebook-pixel";
import Layout from "./components/Layout/Layout";
import HomePage from "./views/HomePage/HomePage";
import OnboardingView from "./views/Onboarding/Onboarding.view";
import { ThankYouPage } from "./views/ThankYouPage/ThankYouPage";
import TAG_MANAGER_ARGS from "./helpers/googleTagManager";
import OnboardingHoc from "./views/Onboarding/OnboardingHoc/OnboardingHoc";
import { SetNewPasswordPage } from "./views/ResetPassword/SetNewPasswordPage";
import { useMediaQuery } from "react-responsive";
import { hsScript } from "./helpers/hsScript";
import { optimizeScript } from "./helpers/googleOptimize";
import { rewardfulBlobScript, rewardfulScript } from "./helpers/rewardfulScript";
import { config } from "./config";
import FirebaseApi from "./api/FirebaseApi/FirebaseApi";

import breakpoints from "./assets/styles/variables/breakpoints.module.scss";
import DoctorInfo from "./views/DoctorInfo/DoctorInfo";
import Profile from "./views/Profile/Profile";
import CancelMembership from "./views/CancelMembership/CancelMembership";
import "react-loading-skeleton/dist/skeleton.css";
import { WelcomeBackPage } from "./views/WelcomeBack/WelcomeBackPage";
import { ForgotPasswordPage } from "./views/ResetPassword/ForgotPasswordPage";
import { ActionGooglePage } from "./views/ActionGooglePage/ActionGooglePage";
import { VerifyEmailPage } from "./views/VerifyEmailPage/VerifyEmailPage";
import { TopmostBanner } from "./components/OnboardingForm/OnboardingFormComponents/TopmostBanner/TopmostBanner";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import { useLogout } from "./hooks/useLogout";

const activityEvents = ["mousedown", "mousemove", "keydown", "scroll"];
const oneMin = 60000;
const fifteenMins = oneMin * 15;

const App = () => {
  const [bannerContent, setBannerContent] = useState<{ text: string; link: string }>({
    text: "",
    link: "",
  });
  const { pathname } = useLocation();

  const isMobileScreen = useMediaQuery({
    query: `(max-width: ${breakpoints["sm"]}px)`,
  });

  const currentUser = firebase.auth().currentUser;
  const { logout } = useLogout();

  // Uncomment to test locally
  //firebase.functions().useFunctionsEmulator("http://localhost:5001");
  const functions = new FirebaseApi().firebaseFunctions();

  const fetchBannerContent = () => {
    functions
      .getBannerContent()
      .then((res) => {
        setBannerContent(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const checkForInactivity = () => {
    const expireTime = localStorage.getItem("expireTime");
    if (Number(expireTime) < Date.now()) {
      logout();
    }
  };

  const updateExpireTime = () => {
    const expireTime = Date.now() + fifteenMins;
    localStorage.setItem("expireTime", `${expireTime}`);
  };

  const throttle = () => {
    let timeout: any = null;
    return () => {
      if (timeout) {
        return;
      }
      updateExpireTime();
      timeout = setTimeout(() => {
        timeout = null;
      }, oneMin);
    };
  };
  const throttleUpdateExpireTime = throttle();

  useEffect(() => {
    if (!currentUser) return;
    const interval = setInterval(() => {
      checkForInactivity();
    }, oneMin);

    return () => clearInterval(interval);
  }, [currentUser]);

  useEffect(() => {
    if (!currentUser) return;
    throttleUpdateExpireTime();

    activityEvents.forEach((eventName) => {
      document.addEventListener(eventName, throttleUpdateExpireTime);
    });

    return () => {
      activityEvents.forEach((eventName) => {
        document.removeEventListener(eventName, throttleUpdateExpireTime);
      });
    };
  }, [currentUser]);

  useEffect(() => {
    if (currentUser) fetchBannerContent();
  }, [currentUser]);

  useEffect(() => {
    document.body.classList.toggle("no-chat", isMobileScreen);
  }, [isMobileScreen]);

  useEffect(() => {
    TagManager.initialize(TAG_MANAGER_ARGS);

    if (process.env.REACT_APP_ENV === "production") {
      hotjar.initialize(config.hjid, config.hjsv);

      amplitude.getInstance().init("6687df4f454182a4bdf5edaf40917146");
    } else {
      amplitude.getInstance().init("aa15d964aa99b9ef789283c73f4cad74");
    }

    const options = {
      autoConfig: true, // set pixel's autoConfig. More info: https://developers.facebook.com/docs/facebook-pixel/advanced/
      debug: false, // enable logs
    };

    ReactPixel.init("170027691568751", undefined, options);
    ReactPixel.pageView();
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <>
      <Helmet>
        {process.env.REACT_APP_ENV !== "production" && (
          <meta name="robots" content="nofollow, noindex" />
        )}

        {process.env.REACT_APP_ENV === "production" && (
          <>
            <script src="https://js.stripe.com/v3/" />
          </>
        )}

        {optimizeScript()}
        {hsScript()}
        {rewardfulBlobScript()}
        {rewardfulScript()}
      </Helmet>

      <FirebaseAuthConsumer>
        {({ isSignedIn }) => {
          if (pathname === "/onboarding") return;
          if (isSignedIn) {
            if (bannerContent.text === "") return;
            return <TopmostBanner isContentBanner contentBanner={bannerContent} />;
          }
        }}
      </FirebaseAuthConsumer>

      <Switch>
        <Route exact path="/thankyou">
          <FirebaseAuthConsumer>
            {({ isSignedIn, user, providerId }) => {
              if (isSignedIn) {
                return <ThankYouPage user={user} />;
              } else {
                return (
                  <OnboardingHoc
                    Component={OnboardingView}
                    providerId={providerId}
                    userEmail={isSignedIn && user.email}
                    uid={isSignedIn && user.uid}
                  />
                );
              }
            }}
          </FirebaseAuthConsumer>
        </Route>

        <Route exact path="/login">
          <FirebaseAuthConsumer>
            {({ isSignedIn, user, providerId }) => {
              return (
                <OnboardingHoc
                  Component={OnboardingView}
                  providerId={providerId}
                  userEmail={isSignedIn && user.email}
                  uid={isSignedIn && user.uid}
                />
              );
            }}
          </FirebaseAuthConsumer>
        </Route>

        <Route exact path="/action">
          <FirebaseAuthConsumer>{() => <ActionGooglePage />}</FirebaseAuthConsumer>
        </Route>

        <Route exact path="/reset-password">
          <FirebaseAuthConsumer>{() => <ForgotPasswordPage />}</FirebaseAuthConsumer>
        </Route>

        <Route exact path="/set-new-password">
          <FirebaseAuthConsumer>{() => <SetNewPasswordPage />}</FirebaseAuthConsumer>
        </Route>
        <Route exact path="/verify-email">
          <FirebaseAuthConsumer>{() => <VerifyEmailPage />}</FirebaseAuthConsumer>
        </Route>

        <Route exact path="/welcome-back">
          <FirebaseAuthConsumer>{() => <WelcomeBackPage />}</FirebaseAuthConsumer>
        </Route>

        <Route exact path="/onboarding">
          <FirebaseAuthConsumer>
            {({ isSignedIn, user, providerId }) => {
              return (
                <OnboardingHoc
                  Component={OnboardingView}
                  providerId={providerId}
                  userEmail={isSignedIn && user.email}
                  uid={isSignedIn && user.uid}
                />
              );
            }}
          </FirebaseAuthConsumer>
        </Route>

        {
          <Route exact path="/home">
            <FirebaseAuthConsumer>
              {({ isSignedIn, user, providerId }) => {
                if (isSignedIn) {
                  return (
                    <HomePage
                      providerId={providerId}
                      userEmail={isSignedIn && user.email}
                      uid={isSignedIn && user.uid}
                    />
                  );
                } else {
                  return (
                    <OnboardingHoc
                      Component={OnboardingView}
                      providerId={providerId}
                      userEmail={isSignedIn && user.email}
                      uid={isSignedIn && user.uid}
                    />
                  );
                }
              }}
            </FirebaseAuthConsumer>
          </Route>
        }

        {
          <Route exact path="/doctor/:id">
            <FirebaseAuthConsumer>
              {({ isSignedIn, user, providerId }) => {
                if (isSignedIn) {
                  return <DoctorInfo uid={isSignedIn && user.uid} />;
                } else {
                  return (
                    <OnboardingHoc
                      Component={OnboardingView}
                      providerId={providerId}
                      userEmail={isSignedIn && user.email}
                      uid={isSignedIn && user.uid}
                    />
                  );
                }
              }}
            </FirebaseAuthConsumer>
          </Route>
        }

        {
          <Route exact path="/user">
            <FirebaseAuthConsumer>
              {({ isSignedIn, user, providerId }) => {
                if (isSignedIn) {
                  return <Profile uid={isSignedIn && user.uid} />;
                } else {
                  return (
                    <OnboardingHoc
                      Component={OnboardingView}
                      providerId={providerId}
                      userEmail={isSignedIn && user.email}
                      uid={isSignedIn && user.uid}
                    />
                  );
                }
              }}
            </FirebaseAuthConsumer>
          </Route>
        }

        {
          <Route exact path="/cancelMembership">
            <FirebaseAuthConsumer>
              {({ isSignedIn, user, providerId }) => {
                if (isSignedIn) {
                  return <CancelMembership uid={isSignedIn && user.uid} />;
                } else {
                  return (
                    <OnboardingHoc
                      Component={OnboardingView}
                      providerId={providerId}
                      userEmail={isSignedIn && user.email}
                      uid={isSignedIn && user.uid}
                    />
                  );
                }
              }}
            </FirebaseAuthConsumer>
          </Route>
        }

        <Route>
          <Layout>
            <Switch>
              <Route path="*">
                <Redirect
                  to={{
                    pathname: "/onboarding",
                    search: window.location.search,
                  }}
                />
              </Route>
            </Switch>
          </Layout>
        </Route>
      </Switch>
    </>
  );
};

export default App;
