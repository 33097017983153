import React from "react";
import cn from "classnames";
import { ReactComponent as IconSpin } from "../../../../assets/icons/spinner.svg";

import styles from "./OnboardingFormSubmit.module.scss";

interface IOnboardingFormSubmit {
  title: React.ReactNode;
  disabled?: boolean;
  isSubmitting?: boolean;
  onClick?: any;
  className?: string;
}

const OnboardingFormSubmit = (props: IOnboardingFormSubmit) => {
  const { title, disabled, isSubmitting, onClick, className } = props;
  return (
    <button
      onClick={onClick}
      className={cn(
        className,
        styles.element,
        disabled && styles["isDisabled"],
        isSubmitting && styles["isSubmitting"]
      )}
      type="submit"
      disabled={disabled}
    >
      {isSubmitting ? <IconSpin className={styles.loader} /> : title}
    </button>
  );
};

export default OnboardingFormSubmit;
